import React, { useContext } from 'react'
import './Styles/PreviewFile.css'
import { ImCross } from "react-icons/im";
import { Context } from '../Context/NoteContext';
import AWS from "aws-sdk";
import db from "../firebase";
import firebase from "firebase/compat/app";


const PreviewFile = ({PersonalChat,channelId}) => {

  const context = useContext(Context)
  const {ShowPreviewFile ,user, setShowPreviewFile,PreviewFileimage,setPreviewFileimage,setUploadFile,UploadFile} = context



  const UploadImage = async ()=>{
    
    const spacesEndpoint = new AWS.Endpoint(
      "https://blr1.digitaloceanspaces.com"
    );
    const s3 = new AWS.S3({
      endpoint: spacesEndpoint,
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    });

    const params = {
      Bucket: "chat-n-task",
      Key:
        PersonalChat === false
          ? `${channelId}/${UploadFile.name}`
          : `${user.uid}/${UploadFile.name}`,
      name: UploadFile.name,
      Body: UploadFile,
      ACL: "public-read",
    };


    try {
      const data = await s3.upload(params).promise();
      console.log("File uploaded successfully. URL:", data);
      sendFiledata(UploadFile.name, data.Location);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
 
  }


  const sendFiledata = (name, downLink) => {
    setShowPreviewFile(false);
    setPreviewFileimage(null)
    setUploadFile(null)

    if (channelId) {
      let payload = {
        timestamp: firebase.firestore.Timestamp.now(),
        user: user.name,
        userImage: user.photo,
        userID: user.uid,
        reactions: [],
        filename: name,
        downloadLink: downLink,
      };
      db.collection("rooms").doc(channelId).collection("messages").add(payload);
    }
    
  }



  return (
    <div className='Previewfile-Container' style={{ width: 'calc(100vw - 260px)' }}  >
      <div onClick={()=>{setShowPreviewFile(false)}} className='Cross'> <ImCross/> </div>

      <div className='Previewfile-image'>
        <img src={PreviewFileimage} alt="" />
      </div>

      <div className='Previewfile-buttons'>
        <div onClick={()=>{UploadImage()}}>Send</div>
      </div>

    </div>
  )
}

export default PreviewFile