import React from "react";
import { Paper, TextField, Button } from "@mui/material";

const EditForm = ({ bookmark, onSave }) => {
    const handleSubmit = (e) => {
        e.preventDefault();
        onSave(e.target.elements.label.value, e.target.elements.url.value);
    };

    return (
        <Paper sx={{ p: 3 }}>
            <form onSubmit={handleSubmit}>
                <TextField
                    name="label"
                    label="Label"
                    defaultValue={bookmark.label}
                    size="small"
                    sx={{ m: 1 }}
                />
                <br />
                <TextField
                    name="url"
                    label="URL"
                    defaultValue={bookmark.url}
                    size="small"
                    sx={{ m: 1 }}
                />
                <br />
                <Button type="submit" variant="contained" sx={{ m: 1 }} >
                    Save
                </Button>
            </form>
        </Paper>
    );
};

export default EditForm;
