import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import db from '../firebase';
import { UsersChatRoomContext } from './UserChatRoomContext';

const UsersDetailsContext = createContext({ users: [], getUser: () => null });

export const UsersDetailsProvider = ({ children }) => {

  const context = useContext(UsersChatRoomContext)

  const { Loader, setLoader, usersChatRooms, setusersChatRooms, rooms, getChannels } = context;


  const [users, setUsers] = useState([]);
  // const [isLoading, setIsLoading] = useState(true);

  const userIdDetailsMap = useMemo(() => {
    const result = {};
    users.forEach(user => (result[user.id] = user));
    return result;
  }, [users]);

  const getUser = useCallback(id => userIdDetailsMap[id], [userIdDetailsMap]);

  useEffect(() => {
    db.collection("userlists").onSnapshot(snapshot =>
      setUsers(snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))),
    );
  }, []);





  // TO GET DATA
  const [user, setUser] = useState({
    accessToken: "",
    name: "",
    photo: "",
    role: "",
    uid: ""
  });

  const getUserDataFromAccessToken = (accessToken) => {





    try {

      const unsubscribe = db.collection('userlists')
        .where('accessToken', '==', accessToken)
        .onSnapshot((userSnapshot) => {
          if (!userSnapshot.empty) {
            const userData = userSnapshot.docs[0].data();
            localStorage.setItem('userRole', userData.role);
            setUser(userData);
            getuserLists(userData);


          } else {
            console.log('User not found in userlists collection.');
            setLoader(false)
          }
        });

    } catch (error) {
      console.error('Error setting up real-time listener for user data:', error);
    }

  };


  useEffect(() => {
    // setLoader(true)
    getUserDataFromAccessToken(localStorage.getItem('accesstoken'))
  }, [localStorage])


  // TO GET DATA





  const [UserList, setUserList] = useState({})

  const [userlists, setUserLists] = useState([])

  const [admin, setadmin] = useState([])
  // to get all the users 


  const getuserLists = (userdata) => {
    try {

      const unsubscribe = db.collection('userlists').onSnapshot((userListsSnapshot) => {
        const userListsData = [];

        const userListsObject = {};

        userListsSnapshot.forEach((doc) => {
          const userData = {
            userId: doc.id,
            username: doc.data().name,
            image: doc.data().photo,
            role: doc.data().role,
            joinDate: doc.data().joinDate,
            email: doc.data().email,
            active_status: doc.data().active_status,
            tag: doc.data()?.tags,
            department: doc.data().department
          };
          userListsData.push(userData);

          const userDataobj = {
            username: doc.data().name,
            image: doc.data().photo,
            role: doc.data().role,
            joinDate: doc.data().joinDate,
            email: doc.data().email,
            status: doc.data()?.status,
            active_status: doc.data().active_status,
            tag: doc.data()?.tags,
            department: doc.data().department


          };
          userListsObject[doc.id] = userDataobj;

        });

        const adminUsers = userListsData.filter(userr => (userr.role === 'admin' && userdata.uid != userr.userId));

        setadmin(adminUsers);
        setUserLists(userListsData);
        setUserList(userListsObject);

        getChannels(userdata, userListsObject);
      });





    } catch (error) {
      console.error('Error setting up real-time listener for user lists:', error);
      return [];
    }
  };


  // to get all the users 







  return (
    <UsersDetailsContext.Provider value={{ users, getUser, setUser, user, UserList, setUserList, admin, setadmin, userlists, setUserLists, getuserLists }}>
      {children}
    </UsersDetailsContext.Provider>
  );
};

export const useUsersDetails = () => {
  return useContext(UsersDetailsContext);
};
