import React, { useContext } from "react";
import { FaUser, FaEnvelope, FaUserTag, FaCalendarAlt } from "react-icons/fa";
import "./Styles/UserProfile.css";
import { useUsersDetails } from "../Context/UsersDetailsContext";
import { useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import db from "../firebase";
import firebase from "firebase/compat/app";
import { FcDepartment } from "react-icons/fc";

const UserProfile = () => {
  const {user} = useUsersDetails();

  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };


  const handleInputKeyDown = (event) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      event.preventDefault();
      addTag();
    }
  };


  const addTag = async () => {
    const trimmedValue = inputValue.trim();
    const userRef = db.collection("userlists").doc(user.uid);

    if (trimmedValue !== "") {
      try {
        await userRef.update({
          tags: firebase.firestore.FieldValue.arrayUnion(trimmedValue),
        });

        setInputValue("");
      } catch (error) {
        console.error("Error updating tags:", error);
      }
    }
  };

  const handleTagRemove = async (index) => {
    const removedTag = user.tags[index];
    const userRef = db.collection("userlists").doc(user.uid);
    try {
      await userRef.update({
        tags: firebase.firestore.FieldValue.arrayRemove(removedTag),
      });
    } catch (error) {
      console.error("Error removing tag:", error);
    }
  };

  return (
    <div className="profile-container">
      <div className="profile-image">
        <img src={user.photo} />
      </div>
      <div className="profile-details">
        <h2>{user.name}</h2>
        <div className="detail">
          <FaEnvelope className="icon" />
          <span>Email </span>
          <p>{user.email}</p>
        </div>
        <div className="detail">
          <FaUserTag className="icon" />
          <span>Role </span>
          <p>{user.role}</p>
        </div>
        <div className="detail">
          <FcDepartment className="icon" />
          <span>Department </span>
          <p>{user.department===undefined?"Unknown":user.department}</p>
        </div>
        <div className="detail">
          <FaCalendarAlt className="icon" />
          <span>Joining Date </span>
          <p> {user.joinDate}</p>
        </div>
      </div>




      <div className="userprofile-tags-adder">
        <div className="userprofile-input-container">
          <input
            type="text"
            placeholder="Add a task tag"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown}
          />
          <button className="userprofile-add-button" onClick={addTag}>
            Add
          </button>
        </div>
        <div className="userprofile-tags-container">
          {user.tags !==undefined && user.tags.map((tag, index) => (
            <div key={index} className="userprofile-tag">
              {tag}
              <AiOutlineCloseCircle
                className="userprofile-delete-icon"
                onClick={() => handleTagRemove(index)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
