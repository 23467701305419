import { createContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import db, { auth } from "../firebase";



export const UsersChatRoomContext = createContext();




export const UserRoomsContext = (props) => {

   
    const [Loader, setLoader] = useState(true)

    const [rooms, setRooms] = useState([])
    const [usersChatRooms, setusersChatRooms] = useState([])
  
  
  
  
  
  
    // to get all personal message rooms 
  
    const fetchPersonalChatRooms = (uid, userlistdata) => {
      try {
        const personalMessagesRef = db.collection('personalMessages').where('users', 'array-contains', uid);
    
        const unsubscribe = personalMessagesRef.onSnapshot(async (roomsSnapshot) => {
          const personalChatRooms = [];
    
          for (const roomDoc of roomsSnapshot.docs) {
  
            const roomData = roomDoc.data();
            const otherUserID = roomData.users.find(id => id !== uid);
    
            // Fetch other user's information from 'userlists'
            const otherUserDoc = await db.collection('userlists').doc(otherUserID).get();
            const otherUserData = otherUserDoc.data();
    
            // TO GET THE NUMBER OF UNREAD MESSAGES
            const unsubscribeUnreadMessages = getUnreadMessageCountListenerPersonal(roomDoc.id, uid, (unreadMessagesCount) => {
              const roomDetails = {
                roomId: roomDoc.id,
                otherUserID,
                otherUserName: otherUserData.name,
                otherUserPhoto: otherUserData.photo,
                active_status: userlistdata[otherUserID].active_status,
                unreadmessages: unreadMessagesCount
              };
              
              // Check if personalChatRooms already has a room with the given roomId
              const existingRoomIndex = personalChatRooms.findIndex(room => room.roomId === roomDetails.roomId);
              if (existingRoomIndex !== -1) {
                personalChatRooms[existingRoomIndex] = roomDetails;
              } else {
                personalChatRooms.push(roomDetails);
              }
              // Check if personalChatRooms already has a room with the given roomId
              setusersChatRooms([...personalChatRooms]);
  
              
            });
          }
    
          setTimeout(() => {
            setLoader(false);
          }, 1500);
          
        });
      } catch (error) {
        console.error('Error setting up real-time listener for personal chat rooms:', error);
      }
    };
  
    // to get all personal message rooms 
  
  
  
  
  
    //  TO GET UNREAD MESSAGES FROM EACH PERSONAL ROOM 
    const getUnreadMessageCountListenerPersonal = (roomId, userId, callback) => {
      const roomRef = db.collection('personalMessages').doc(roomId).collection('messages');
    
      return roomRef.where('unreaded_by', 'array-contains', userId)
          .limit(100)
          .onSnapshot(querySnapshot => {
              let unreadedmessages = querySnapshot.size; 
              callback(unreadedmessages);
          }, error => {
              console.error('Error getting unread message count:', error);
              callback(0);
          });
    };
  
  
  


  
  
  
  
  
  
  // TO get channels 
  const getChannels = async (userdata, userlistdata) => {
    const roomsRef = db.collection('rooms');
  
    // Set up active listener for channels
    const unsubscribeChannels = roomsRef.onSnapshot(async (snapshot) => {
      const userRooms = await Promise.all(  snapshot.docs
        .filter(doc => {
          const members = doc.data().members || [];
          return members.some(member => member.userid === userdata.uid);
        })
        .map(async doc => {
          const roomData = doc.data();
          const memberData = (roomData.members || []).find(member => member.userid === userdata.uid);
          const isRestricted = memberData ? memberData.isRestricted : false;
  
          // TO GET THE NUMBER OF UNREAD MESSAGES 
          const unsubscribeUnreadMessages = getUnreadMessageCountListener(doc.id, userdata.uid, (unreadMessagesCount) => {
            // TO GET THE NUMBER OF UNREAD MESSAGES 
            const roomDetails = {
              id: doc.id,
              name: roomData.name,
              isRestricted: isRestricted,
              unreadmessages: unreadMessagesCount,
            };
  
            setRooms(prevRooms => {
              const updatedRooms = prevRooms.map(prevRoom => (prevRoom.id === roomDetails.id ? roomDetails : prevRoom));
              return updatedRooms;
            });
          });
  
          return {
            id: doc.id,
            name: roomData.name,
            isRestricted: isRestricted,
            unsubscribeUnreadMessages, // Add unsubscribe function to be able to stop listening when necessary
          };
        }));
  
      setRooms(userRooms);
      // console.log(userRooms);
    });
  
    fetchPersonalChatRooms(userdata.uid, userlistdata);
  
    return unsubscribeChannels; // Return the unsubscribe function
  };
  
  
  // TO GET UNREAD MESSAGES FROM EACH ROOM 
  const getUnreadMessageCountListener = (roomId, userId, callback) => {
    const roomRef = db.collection('rooms').doc(roomId).collection('messages');
  
    return roomRef.where('unreaded_by', 'array-contains', userId)
        .limit(100)
        .onSnapshot(querySnapshot => {
            let unreadedmessages = querySnapshot.size; 
            callback(unreadedmessages);
        }, error => {
            console.error('Error getting unread message count:', error);
            callback(0);
        });
  };
  // TO GET UNREAD MESSAGES FROM EACH ROOM 
  
  
  
  
  
  
  
  
  
    
  
  
    return (
      <UsersChatRoomContext.Provider value={{
        usersChatRooms, setusersChatRooms, rooms, Loader,setLoader,getChannels
      }} >
        {props.children}
      </UsersChatRoomContext.Provider>
  
    )
  
  }