import "./App.css";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Chat from "./components/Chat";
import Login from "./components/Login";
import styled from "styled-components";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import Task from "./components/Task";
import db, {
  onMessageListener,
  requestNotificationPermission,
} from "./firebase";
import { auth, provider } from "./firebase";
import PersonalChat from "./components/PersonalChat";
import UserList from "./components/UserList";
import { NoteContext } from "./Context/NoteContext";
import Alert from "./components/Alert";
import firebase from "firebase/compat/app";
import ChatnTaskLoader from "./components/ChatnTaskLoader";
import toast, { Toaster } from "react-hot-toast";

import Notification from "./components/Notification";

import Bookmark from "./components/Bookmark";
import { UsersDetailsProvider } from "./Context/UsersDetailsContext";
import UserStatus from "./components/UserStatus";
import UserProfile from "./components/UserProfile";
import { UserRoomsContext } from "./Context/UserChatRoomContext";
import UserMentions from "./components/UserMentions";
import Notes from "./components/Notes";
import AddNotesModal from "./components/AddNotesModal";
import Broadcast from "./components/Broadcast";

function App() {
  const [notificationAllowed, setNoficationAllowed] = useState();

  const [cuser, setcUser] = useState({
    accessToken: "",
    name: "",
    photo: "",
    role: "",
    uid: "",
  });

  const getUserDataFromAccessToken = (accessToken) => {
    try {
      const unsubscribe = db
        .collection("userlists")
        .where("accessToken", "==", accessToken)
        .onSnapshot((userSnapshot) => {
          if (!userSnapshot.empty) {
            const userData = {
              ...userSnapshot.docs[0].data(),
              id: userSnapshot.docs[0].id,
            };
            setcUser(userData);
          } else {
            setcUser(null);
          }
        });
    } catch (error) {
      console.error(
        "Error setting up real-time listener for user data:",
        error
      );
    }
  };

  useEffect(() => {
    getUserDataFromAccessToken(localStorage.getItem("accesstoken"));
  }, [localStorage]);

  useEffect(() => {
    cuser?.id &&
      requestNotificationPermission((notificationToken) => {
        setNoficationAllowed(!!notificationToken);
        console.log(notificationToken)
        if (cuser?.notification_token != notificationToken) {
          db.collection("userlists")
            .doc(cuser.id)
            .update({ notification_token: notificationToken });
        }
      });
  }, [cuser?.id]);

  onMessageListener()
    .then((payload) => {
      // setShow(true);
      // setNotification({title: payload.notification.title, body: payload.notification.body})
      toast(`${payload.notification.title}\n${payload.notification.body}`);
      console.log(payload);
    })
    .catch((err) => console.log("failed: ", err));

  return (
    <Router>
      <UserRoomsContext>
        <UsersDetailsProvider>
      <NoteContext>
      <AddNotesModal/> 
          <Notification />
          <Toaster />

          <ChatnTaskLoader />
          <Alert />
          <div className="App">
            {!cuser ? (
              <Login setcUser={setcUser} />
              ) : (
                <Container>
                <Header setcUser={setcUser} cuser={cuser} />
                
                <Main>
                  <UserStatus/>
                  <Sidebar />
                  <Routes>
                    <Route path="/Users" element={<UserList />} />
                    <Route
                      path="/room/:channelId"
                      element={<Chat user={cuser} />}
                      />
                    <Route path="/Task" element={<Task />} />
                    <Route
                      path="/personalroom/:channelId"
                      element={<PersonalChat user={cuser} />}
                      />
                    <Route path="/Bookmark" element={<Bookmark />} />
                    <Route path="/Broadcast" element={<Broadcast />} />
                    <Route path="/Notes" element={<Notes />} />
                    <Route path="/Mentions" element={<UserMentions />} />
                    <Route path="/UserProfile" element={<UserProfile />} />
                  </Routes>
                </Main>
              </Container>
            )}
          </div>
      </NoteContext>
        </UsersDetailsProvider>
            </UserRoomsContext>
    </Router>
  );
}

export default App;

const Container = styled.div`
  width: 100%;
  height: 100dvh;
  display: grid;
  grid-template-rows: 0px minmax(0, 1fr);
`;

const Main = styled.div`
  display: grid;
  margin-top: 45px;
  grid-template-columns: 260px auto;
`;