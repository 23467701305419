import React, { useContext } from 'react';
import StyledInput from './Common/Input';
import StatusButton from './Common/StatusButton';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import './Styles/Task.css';
import './Styles/Chat.css'
import { Paper, Box } from '@mui/material';
import { Context } from '../Context/NoteContext';
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

const TaskComment = ({ detail, CommentData, setcommentInput, handleSubmitComment, seteditData, handleChange, editData }) => {
  const context = useContext(Context);
  const { userlists } = context;
  // console.log(editData)
  return (
    <div >
      <Box sx={{
        padding: "15px", backgroundColor: "#F3F3F3", maxHeight: "90vh", overflowY: "auto", "&::-webkit-scrollbar": { width: "5px" }
      }}>

        <div className="item">
          <div className="task-item">{detail?.title}</div>
          <div className="task-item" style={{ fontSize: "14px", whiteSpace: "pre-wrap" }}>{detail?.description}</div>
          <TextField
            label="due date"
            name="due_date"
            value={editData?.due_date}
            onChange={(e) => { seteditData({ ...editData, due_date: e.target.value }) }}
            size="small"
            sx={{ backgroundColor: "white", mt: 1 }}
            type="date"
            InputLabelProps={{ shrink: true }}
          />
          <FormControl sx={{ mt: 1, ml: 6, maxWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label">Status</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={editData?.status}
              label="Status"
              onChange={(e) => { seteditData({ ...editData, status: e.target.value }) }}
            >
              <MenuItem value={"Pending"}>Pending</MenuItem>
              <MenuItem value={"Complete"}>Complete</MenuItem>
              <MenuItem value={"Running"}>Running</MenuItem>
            </Select>
          </FormControl></div>



        {detail?.tag?.length > 0 ? <div className="tags ">
          <ul>
            <li>
              {detail?.tag?.map((tagItem, index) => (
                <li key={index}>{tagItem}</li>
              ))}
            </li>
          </ul>
        </div> : ""}
        <Autocomplete
          value={editData?.assigned_to}
          onChange={(event, newValue) =>
            seteditData({
              ...editData,
              assigned_to: newValue,
            })
          }
          options={userlists}
          getOptionLabel={(option) => option.username || ""}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ width: "200px", mt: 1 }}
              label="Assigned To"
              variant="outlined"
              size="small"
            />
          )}
        />
        <Autocomplete
          value={editData?.reporting_person}
          onChange={(event, newValue) =>
            seteditData({
              ...editData,
              reporting_person: newValue,
            })
          }
          options={userlists}
          getOptionLabel={(option) => option.username || ""}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ width: "200px", mt: 1, mb: 1 }}
              label="Reporting To"
              variant="outlined"
              size="small"
            />
          )}
        />
        <StatusButton
          style={{ background: "#085FE2", cursor: "pointer" }}
          onClick={handleChange}
          data={"Save"}
        />
        <div className="line" />

        <div className="comment-container">
          {CommentData?.map((item) => (
            <div className="comment" key={item.id}>
              <div className="comment-header">
                <div >{userlists.find((user) => user.userId === item.created_by)?.username}</div>
                <div> {new Date(item.created_on?.toDate()).toLocaleString('en-IN', {
                  day: "numeric",
                  month: 'short',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                })}</div>

              </div>
              <div className="comment-body">{item.comment}</div>
            </div>
          ))}
        </div>
        <StyledInput
          placeholder="Type here..."
          onChange={(e) => setcommentInput(e.target.value)}
          style={{ marginTop: "10px" }}
        />
        <StatusButton
          style={{ background: "#085FE2", cursor: "pointer" }}
          id={detail?.id}
          onClick={handleSubmitComment}
          data={"Submit"}
        />

      </Box >
    </div >

  );
};

export default TaskComment;
