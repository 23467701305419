import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { MdAccessTime } from 'react-icons/md';
import { BsQuestionCircle } from 'react-icons/bs';
import { Context } from '../Context/NoteContext';
import { useUsersDetails } from "../Context/UsersDetailsContext";
import { RiLogoutBoxRLine } from "react-icons/ri";
import './Styles/Header.css'
import db from '../firebase';
import './Styles/UserList.css';
import statusOptions from '../data/StatusOption';



function Header({ setcUser, cuser }) {

    const context = useContext(Context)

    const {  signOut } = context
    const { user } = useUsersDetails()

    const clickedOut = () => {
        const userRef = db.collection('userlists').doc(user.uid && user.uid);

        userRef.update({
            active_status: 'inactive',
        })
            .catch((error) => {
                console.error('Error updating user status in Firestore:', error);
            });

        setcUser();
        signOut();
        localStorage.clear()
    }

    const handleStatusChange = async (newStatus) => {

        try {
            console.log(newStatus)
            const userRef = db.collection('userlists').doc(user.uid);

            await userRef.update({
                status: newStatus
            });

            console.log(`User ${user.userId} status updated to ${newStatus}`);
        } catch (error) {
            console.error('Error changing user status:', error);
        }
    };;


    return (
        <div className='header-container'>

            <div className='userheader'>
                <div className='userstatus'>
                    <select
                        value={user.status}
                        onChange={(e) => handleStatusChange(e.target.value)}
                        className='userrole-dropdown'
                        style={{ marginRight: "9px" }}
                    >
                        {statusOptions.map(option => (
                            <option key={option.value} value={option.value} style={{ color: option.color }}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>
                <div className='userheader-name' style={{ marginLeft: "2px" }}>
                    {user.name}
                </div>
                <div className='userheader-image'  >
                    <img src={user.photo ? user.photo : "https://i.imgur.com/6VBx3io.png"} />
                </div>

                <RiLogoutBoxRLine className='logout' onClick={() => { clickedOut() }} />

            </div>
        </div>
    )
}

export default Header





