import React, { useState, useEffect, useContext } from 'react';
import db from '../firebase';
import firebase from 'firebase/compat/app';
import TaskInput from './TaskInput';
import Button from './Common/StatusButton';
import TableTask from './TableTask';
import Modals from './Common/Models';
import { Context } from '../Context/NoteContext';
import Paper from '@mui/material/Paper';
import './Styles/Task.css';
import TaskComment from './Taskcomment';
import './Styles/Chat.css'

const Task = () => {
  const [detail, setdetail] = useState([]);
  const [CommentData, setCommentData] = useState([]);
  const [commentInput, setcommentInput] = useState("");
  const [show, setshow] = useState(false);
  const [TaskData, setTaskData] = useState([])
  const [openComment, setopenComment] = useState(false)

  const context = useContext(Context);
  const { openModal, setOpenModal, userlists, settaskUpdate } = context;


  useEffect(() => {
    if (detail.id) {
      getComments();
      seteditData({
        ...editData, status: detail?.status, due_date: detail?.due_date, assigned_to: { userId: detail?.assigned_to, username: userlists.find((user) => user.userId === detail?.assigned_to)?.username },
        reporting_person: { userId: detail?.reporting_person, username: userlists.find((user) => user.userId === detail?.reporting_person)?.username },
      })
    }
  }, [detail]);

  const handleCardClick = (clickedItem) => {
    setdetail(clickedItem);
    setopenComment(true)
  };

  const handleSubmitComment = () => {
    if (commentInput.trim() !== '') {
      let payload = {
        created_on: firebase.firestore.Timestamp.now(),
        created_by: localStorage.getItem("userId"),
        status: detail?.status,
        comment: commentInput,
      };
      db.collection('Task').doc(detail?.id).collection('comments').add(payload);
    }
  };

  const getComments = () => {
    if (detail.id)
      db.collection('Task').doc(detail?.id).collection('comments').onSnapshot(
        (commentSnapshot) => {
          const taskComments = commentSnapshot.docs?.map((commentDoc) => ({
            id: commentDoc.id,
            ...commentDoc.data(),
          }));
          setCommentData(taskComments);
          console.log(taskComments)
        },
        (error) => {
          console.log('Error fetching comments:', error);
        }
      );
  };
  const [editData, seteditData] = useState({
    status: "",
    due_date: "",
    assigned_to: "",
  });

  const taskEditHandler = () => {
    console.log(detail)
    db.collection("Task")
      .doc(detail?.id)
      .update({
        status: editData?.status,
        due_date: editData?.due_date,
        assigned_to: editData?.assigned_to?.userId || null,
        reporting_person: editData?.reporting_person?.userId || null,
      })
      .then(() => {
        console.log("Document status updated successfully");
        settaskUpdate(prevState => !prevState)

      })
      .catch((error) => {
        console.error("Error updating document status:", error);
        alert(error)
      });

  };
  const formatDueDate = (dueDate) => {
    const dateObject = new Date(dueDate);
    return dateObject.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  };


  return (
    <div class="message-container" >
      <div className="main-task ">
        {show ? (
          <>
            <div className="container-task">
              {TaskData?.map((item) => (
                <Paper sx={{ backgroundColor: "#F3F3F3", p: 2 }} className="card-task" onClick={() => handleCardClick(item)}>
                  <div className="item">Due on {formatDueDate(item.due_date)}</div>
                  <div className="task-item">{item.title}</div>
                  <Button style={{ background: item.color }} data={item.status} />
                  <div className="tags">
                    <ul style={{ display: "flex" }}>
                      {item?.tag?.map((tagItem, index) => (
                        <li style={{ marginRight: "20px" }} key={index}>
                          {tagItem}
                        </li>
                      ))}
                    </ul>
                  </div>
                </Paper>
              ))}

            </div>
          </>
        ) : (
          <TableTask setdetail={setdetail} setopenComment={setopenComment} />
        )}

        {/* comment section */}
        {detail?.id ?
          <Modals open={openComment} handleClose={() => setopenComment(false)} taskInputComponent={<TaskComment
            detail={detail}
            CommentData={CommentData}
            setcommentInput={setcommentInput}
            handleSubmitComment={handleSubmitComment}
            seteditData={seteditData}
            handleChange={taskEditHandler}
            editData={editData}

          />} />
          : ""}
      </div>

      < Modals open={openModal} handleClose={() => setOpenModal(false)} taskInputComponent={< TaskInput reportingPerson={localStorage.getItem("userId")} />} />
    </div >
  );
};

export default Task;


