import React, { useState } from 'react';
import { Resend } from 'resend';
import './Styles/Emoji.css'

function Emoji({handleReaction}) {
  

  const emojis = ['👍', '😍', '🥳', '😂', '😭', '➕'];

  // const handleEmojiClick = (emoji) => {
  //   console.log(emoji)
  // };

  return (
    <div className="emoji-picker-comp">
    {emojis.map((emoji, index) => (
      <div
        key={index}
        className={`emoji-item-comp`}
        onClick={() => handleReaction(emoji)}
      >
        {emoji}
      </div>
    ))}
  </div>
  );
}

export default Emoji;
