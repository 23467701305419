import { useCallback, useEffect, useContext, useState } from 'react';
import { Context } from "../Context/NoteContext";
import db from "../firebase";

const useCompletedTasks = ({ enabled = false }) => {
    const [forUser, setForUser] = useState([]);
    const [byUser, setByUser] = useState([]);
    const [forReportToUser, setForReportToUser] = useState([]);

    const context = useContext(Context);
    const { taskUpdate, settaskUpdate } = context;

    const getTimestampFromSecondsAndNanoSeconds = ({ seconds, nanoseconds }) => {
        return seconds * 1000 + nanoseconds / 1000000;
    }
    const fetchTasks = useCallback((value = false) => {
        const userId = localStorage.getItem("userId");

        if (!userId) return;

        const queryTasks = field => {
            const oldestTask = (
                field === 'reporting_person'
                    ? forReportToUser
                    : field === 'assigned_to'
                        ? forUser
                        : field === 'created_by'
                            ? byUser
                            : []
            ).sort((a, b) => getTimestampFromSecondsAndNanoSeconds(a.created_on_tt) - getTimestampFromSecondsAndNanoSeconds(b.created_on_tt))?.[0];
            let query = null;
            if (oldestTask?.created_on_tt && !value) {
                console.log("second ")
                query = db
                    .collection("Task")
                    .orderBy('created_on_tt', 'desc')
                    .where(field, '==', userId)
                    .startAfter(oldestTask.created_on_tt)
                    .where('status', '==', 'Complete')
                    .limit(5);
            } else {
                console.log("first")
                query = db
                    .collection("Task")
                    .orderBy('created_on_tt', 'desc')
                    .where(field, '==', userId)
                    .where('status', '==', 'Complete')
                    .limit(5);
            }

            query
                .get()
                .then(snapshot => {
                    if (field === 'reporting_person') {
                        if (value) setForReportToUser([...snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))])
                        else setForReportToUser([
                            ...forReportToUser.reverse(),
                            ...snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })),
                        ]);
                    }
                    else if (field === 'assigned_to') {
                        if (value) setForUser([...snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))])
                        else
                            setForUser([
                                ...forUser.reverse(),
                                ...snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })),

                            ]);
                    }
                    else if (field === 'created_by') {
                        if (value) setByUser([...snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))])
                        else setByUser([
                            ...byUser.reverse(),
                            ...snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })),
                        ]);
                    }
                })
                .catch(error => console.error(error));

        };

        queryTasks('reporting_person');
        queryTasks('assigned_to');
        queryTasks('created_by');
    }, [forUser, byUser, forReportToUser]);

    useEffect(() => {
        if (enabled) {
            fetchTasks();
        }
    }, [enabled]);

    useEffect(() => {
        if (taskUpdate) {
            console.log("called")
            fetchTasks(true);
            settaskUpdate(false)
        }
    }, [taskUpdate]);

    return { tasks: { assigned_to: forUser, created_by: byUser, reporting_person: forReportToUser }, fetchNext: fetchTasks };
};

export default useCompletedTasks;