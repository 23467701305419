/**
 * Sends a push notification to the specified tokens using Firebase Cloud Messaging (FCM).
 * @async
 * @param {string[]} tokens - An array of device tokens to send the notification to.
 * @param {string} title - The title of the push notification.
 * @param {string} body - The body text of the push notification.
 * @returns {Promise<void>} - A Promise that resolves when the notification is sent successfully.
 */
export const sendPushNotification = async (tokens, title, body) => {
  //console.log("token==>", token);

  const MESSAFING_SERVER_KEY = process.env.REACT_APP_MESSAFING_SERVER_KEY

  const message = {
    registration_ids: tokens,
    notification: {
      title: title,
      body: body,
      vibrate: 1,
      sound: 1,
      show_in_foreground: true,
      priority: "high",
      content_available: true,
    },
  };

  let headers = new Headers({
    "Content-Type": "application/json",
    Authorization: "key=" + MESSAFING_SERVER_KEY,
  });

  let response = await fetch("https://fcm.googleapis.com/fcm/send", {
    method: "POST",
    headers,
    body: JSON.stringify(message),
  });
  // console.log("=><*", response);
  response = await response.json();
  //  console.log("=><*", response);
};
