import React, { useContext, useEffect, useState } from 'react'
import './Styles/AddNotesModal.css'
import { Context } from '../Context/NoteContext';
import firebase from "firebase/compat/app";
import db from '../firebase';
const AddNotesModal = ({}) => {

    const context = useContext(Context);

    const {setopenNotesModal,openNotesModal,user,Update,activenote} = context
    const [pin, setpin] = useState(false)
    const [title, settitle] = useState('')
    const [note, setnote] = useState('')




    useEffect(() => {

      if(Update === true )
      {
        const formattedText = activenote.Content.replace(/<br>/g, '\n');
        setpin(activenote.Pin)
        settitle(activenote.Heading)
        setnote(formattedText)
      }
      else{
        setpin(false)
setnote('')
settitle('')
      }
        
    }, [openNotesModal])
    




    const Addnote = ()=>{

      const formattedText = note.replace(/\n/g, '<br>');


 const payload = {
  Created_on: firebase.firestore.Timestamp.now(),
  Heading: title,
  Content : formattedText,
  Created_by : user.uid,
  Pin:pin
}
setpin(false)
setnote('')
settitle('')
setopenNotesModal(false)
db.collection('UserNotes').doc(user.uid).collection('notes')
      .add(payload)
    }




    const Updatenote = ()=>{
      const formattedText = note.replace(/\n/g, '<br>');
 const payload = {
  Heading: title,
  Content : formattedText,
  Pin:pin
}

setopenNotesModal(false)
setpin(false)
setnote('')
settitle('')

db.collection('UserNotes').doc(user.uid).collection('notes').doc(activenote.id).update(payload)
    }


    
const deletenote = ()=>{
  db.collection("UserNotes").doc(user.uid).collection('notes').doc(activenote.id).delete()
  setopenNotesModal(false)
  setpin(false)
setnote('')
settitle('')
}







  return (
<>
{
  Update === false ?

    <div className={`${openNotesModal===false ? 'hidemodalnotes':'AddmodalContainer'} `}>
        <div className='subAddmodalContainer'>


        <div onClick={()=>{setopenNotesModal(false)}} className="Addmodalremove"></div>

        <div className="AddmodalMain">

            <input value={title} onChange={(e)=>{settitle(e.target.value)}} placeholder='Add  Title' type="text" className='notes-title' />
            <textarea  value={note} onChange={(e)=>{setnote(e.target.value)}} placeholder='Add Note' name="" id=""  rows="10" className='notes-input'></textarea>
            <div className='pinandaddnotes'>
            <div onClick={()=>{setpin(!pin)}} className='pinnote'>{pin===true?'Unpin this' : 'Pin this'}</div>
            <div onClick={()=>{Addnote()}} className='addnote'>Add Note</div>
            </div>
        </div>

        </div>
        
    </div>
    :


    <div className={`${openNotesModal===false ? 'hidemodalnotes':'AddmodalContainer'} `}>
        <div className='subAddmodalContainer'>


        <div onClick={()=>{setopenNotesModal(false); setpin(false); setnote(""); settitle("")}} className="Addmodalremove"></div>

        <div className="AddmodalMain">

            <input value={title} onChange={(e)=>{settitle(e.target.value)}} placeholder='Add  Title' type="text" className='notes-title' />
            
            <textarea  value={note} onChange={(e)=>{setnote(e.target.value)}} placeholder='Add Note' name="" id=""  rows="10" className='notes-input'></textarea>

            <div className='pinandaddnotes'>
            <div onClick={()=>{setpin(!pin)}} className='pinnote'>{pin===true?'Unpin this' : 'Pin this'}</div>

            <div onClick={()=>{deletenote()}} className='Deletenote'>Delete Note</div>

            <div onClick={()=>{Updatenote()}} className='addnote'>Update Note</div>
            </div>
        </div>

        </div>
        
    </div>

    }
</>
  )
}

export default AddNotesModal