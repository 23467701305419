import { createContext, useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import db, { auth } from "../firebase";
import { UsersChatRoomContext } from "./UserChatRoomContext";
import { useUsersDetails } from "../Context/UsersDetailsContext";

export const Context = createContext();





export const NoteContext = (props) => {

  const context = useContext(UsersChatRoomContext)

  const { users, getUser, setUser, user, UserList, setUserList, admin, setadmin, userlists, setUserLists, getuserLists } = useUsersDetails();

  const { Loader, setLoader, usersChatRooms, setusersChatRooms, rooms, getChannels } = context;

  const [ShowPreviewFile, setShowPreviewFile] = useState(false);
  const [UploadFile, setUploadFile] = useState(null);
  const [PreviewFileimage, setPreviewFileimage] = useState(null);


  const [openModal, setOpenModal] = useState(false);
  const [openNotesModal, setopenNotesModal] = useState(false);
  const [activeunreadmessagesCount, setactiveunreadmessagesCount] = useState(0);










  // FUNCTION TO SHOW ALERT 

  const [alerttype, setalerttype] = useState('')
  const [alertmessage, setalertmessage] = useState('')
  const [showAlert, setShowAlert] = useState(false);
  const [taskUpdate, settaskUpdate] = useState(false);

  useEffect(() => {
    if (user && user.role === 'noaccess') {
      setalerttype('normal')
      setalertmessage('Access Denied')
      setShowAlert(true);


      setTimeout(() => {
        setShowAlert(false);
        setalerttype('')
        setalertmessage('')
      }, 200000000);

    }

    else {
      setShowAlert(false);
      setalerttype('')
      setalertmessage('')
    }
  }, [user])


  const alert = (type, message, time) => {

    setalerttype(type)
    setalertmessage(message)
    setShowAlert(true);


    setTimeout(() => {
      setShowAlert(false);
      setalerttype('')
      setalertmessage('')
    }, time);

  }


  // FUNCTION TO SHOW ALERT 


  const signOut = () => {
    auth.signOut().then(() => {
      // console.log(user)
      localStorage.removeItem('user');
      setUser(null);
    })
  }


  const [Update, setUpdate] = useState(false)
  const [activenote, setactivenote] = useState({})


  const AddNote = () => {
    setUpdate(false)
    setopenNotesModal(true);
  }

  const UpdateNote = (note) => {
    setUpdate(true)
    setopenNotesModal(true);
    setactivenote(note)

  }




  return (
    <Context.Provider value={{
      setUser, user, signOut, getuserLists, usersChatRooms, setusersChatRooms, admin, rooms, setUserLists, userlists,
      setShowAlert, alert, showAlert, alertmessage, alerttype, openModal, setOpenModal, Loader
      , UserList, setactiveunreadmessagesCount, activeunreadmessagesCount, openNotesModal, setopenNotesModal, AddNote, UpdateNote, Update, activenote, ShowPreviewFile, setShowPreviewFile, PreviewFileimage, setPreviewFileimage, UploadFile, setUploadFile
      , taskUpdate, settaskUpdate
    }} >
      {props.children}
    </Context.Provider>

  )

}