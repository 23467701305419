import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../Context/NoteContext'
import { useUsersDetails } from "../Context/UsersDetailsContext";
import db from '../firebase';
import Spinner from "./Spinner";
import { GiPin } from "react-icons/gi";
import './Styles/Notes.css'
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

const Notes = () => {
  const context = useContext(Context)
  const {UpdateNote} = context;
  const { user} = useUsersDetails();
  
  const [pinnednotes, setpinnednotes] = useState([])
  const [notes, setnotes] = useState([])
  const [showspinner, setshowspinner] = useState(true)



  
const getNotes = () => {
  return db
    .collection("UserNotes")
    .doc(user.uid)
    .collection("notes")
    .orderBy("Created_on", "desc")
    .onSnapshot((snapshot) => {
      const pinned = [];
      const unpinned = [];

      snapshot.docs.forEach((doc) => {
        const notesdata = {
          id: doc.id,
          ...doc.data(),
        };
        console.log("first", notesdata);
        if (doc.data().Pin === false) {
          unpinned.push(notesdata);
        } else {
          pinned.push(notesdata);
        }
      });

      setnotes(unpinned);
      setpinnednotes(pinned);
      setshowspinner(false);
    });
};

useEffect(() => {
  setshowspinner(true);

  let unsubscribe; 

  if (user.uid !== "") {
    unsubscribe = getNotes();
  }

  return () => {
    if (unsubscribe) {
      unsubscribe();
    }
  };
}, [user]);





const convertTextToHTML = (text) => {
  return {
    __html: text,
  };
};





  return (

    <>
    {  
     showspinner?
  
     <Spinner/>
     :


    <div className='Notes-container'>

     

     
      { pinnednotes.length >0 &&
      <div className='notes-pinned'>
        {
          pinnednotes.map((note)=>(
            <div className='notes-box'>
            <div className='noteheadingpinned'>{note.Heading} <span className='notes-actions'><GiPin/> <FaEdit onClick={()=>{ UpdateNote(note)}} /> </span></div>
            <div className='notecontent' dangerouslySetInnerHTML={convertTextToHTML(note.Content)} ></div>
          </div>
        ))
      }
          </div>
      }


          <br />
          <br />

          <div className='notes'>

{
  notes.map((note)=>(
    <div className='notes-box'>

      <div className='noteheadingpinned'>{note.Heading} <span className='notes-actions'><FaEdit onClick={()=>{UpdateNote(note) }} /> </span> </div>
      <div className='notecontent' dangerouslySetInnerHTML={convertTextToHTML(note.Content)} ></div>
    </div>
  ))
}

    </div>

      
    </div>
}
    </>
  )
}

export default Notes