import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { BsInfoCircle } from "react-icons/bs";
import ChatInput from "./ChatInput";
import ChatMessage from "./ChatMessage";
import db from "../firebase";
import { useParams } from "react-router-dom";
import firebase from "firebase/compat/app";
import "./Styles/PersonalChat.css";
import StatusButton from "./Common/StatusButton";
import { useUsersDetails } from "../Context/UsersDetailsContext";
import { sendPushNotification } from "../utils/firebase";
import PinnedMessages from "./PinnedMessages";

const PersonalChat = ({}) => {
  const messageContainerRef = useRef(null);

  const { user, UserList} = useUsersDetails();
  const { getUser } = useUsersDetails();

  let { channelId } = useParams();
  const [messages, setMessages] = useState([]);
  const [Pinnedmessages, setPinnedmessages] = useState([]);
  const [channel, setchannel] = useState({});
  const getChannelUnsubscribe = useRef(null);
  const getMessagesUnsubscribe = useRef(null);
  const [receiverId, setReceiverId] = useState();
  const [taskTitle, settaskTitle] = useState("");
  const [reportingPersonId, setreportingPersonId] = useState("");

  const [LatestTimeStamp, setLatestTimeStamp] = useState();

  const receiverUser = useMemo(
    () => getUser(receiverId),
    [getUser, receiverId]
  );

  const getMessages = () => {
    if (Object.keys(UserList).length > 1) {
      getMessagesUnsubscribe.current = db
        .collection("personalMessages")
        .doc(channelId)
        .collection("messages")
        .orderBy("timestamp", "desc")
        .limit(10)
        .onSnapshot((snapshot) => {
          let messages = snapshot.docs.map((doc) => ({
            id: doc.id,
            user: UserList[doc.data().userID].username,
            userImage: UserList[doc.data().userID].image,
            ...doc.data(),
          }));
          messages.length>0&&setLatestTimeStamp(messages[0].timestamp)
          setMessages(messages);
        });
    }
  };

  useEffect(() => {
    const container = messageContainerRef.current;
    if (container) {
      container.scrollTop = 0;
    }
  }, [LatestTimeStamp])

  // Function to send a message in a personal chat room
  const sendMessage = async (text) => {
    const formattedText = text.replace(/\n/g, "<br>");

    try {
      const messageRef = db
        .collection("personalMessages")
        .doc(channelId)
        .collection("messages");
      await messageRef.add({
        text: formattedText,
        timestamp: firebase.firestore.Timestamp.now(),
        userID: user.uid,
        reactions: [],
        unreaded_by: [channel.uid],
      });
      console.log("Message sent successfully");
      if (receiverUser?.notification_token) {
        sendPushNotification(
          [receiverUser.notification_token],
          `New Message From: ${user.name}`,
          text
        );
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const getChannel = () => {
    getChannelUnsubscribe.current = db
      .collection("personalMessages")
      .doc(channelId)
      .onSnapshot((snapshot) => {

        
        if (snapshot.data().PinnedMessages !== undefined) {
          setPinnedmessages(snapshot.data().PinnedMessages)
        }

        
        const filteredIds = snapshot
          .data()
          .users.filter((userId) => userId !== user.uid);

        setReceiverId(snapshot.data().users.find((id) => id != user.uid));

        db.collection("userlists")
          .doc(filteredIds[0])
          .get()
          .then((userSnapshot) => {
            if (userSnapshot.exists) {
              const userData = userSnapshot.data();
              setchannel(userData);
              getMessages();
            } else {
              console.log("User not found");
              return null;
            }
          });
      });
  };

  useEffect(() => {
    getChannel();

    return () => {
      // Unsubscribe when component is unmounted
      if (getChannelUnsubscribe.current) {
        getChannelUnsubscribe.current();
      }
      if (getMessagesUnsubscribe.current) {
        getMessagesUnsubscribe.current();
      }
    };
  }, [channelId, user, UserList]);

  // TO MARK COMMENTS AS READ
  useEffect(() => {
    const markAllMessagesAsRead = async () => {
      try {
        // Get a reference to the messages collection in the specified channel
        const messagesRef = db
          .collection("personalMessages")
          .doc(channelId)
          .collection("messages");

        // Get all the messages in the channel
        const messagesSnapshot = await messagesRef.get();

        // Loop through each message and mark it as read for the user
        messagesSnapshot.forEach((messageDoc) => {
          const messageId = messageDoc.id;
          const messageRef = messagesRef.doc(messageId);

          messageRef.update({
            unreaded_by: firebase.firestore.FieldValue.arrayRemove(user.uid),
          });
        });
      } catch (error) {
        console.error("Error marking messages as read:", error);
      }
    };

    markAllMessagesAsRead();
  }, [messages, channelId]);

  // TO MARK COMMENTS AS READ












  // TO GET OLD MESSAGES 
  const fetchOldMessages = () => {
    const oldestMessage = messages.reduce((oldest, current) =>
      oldest.timestamp < current.timestamp ? oldest : current
    );

    db.collection("personalMessages")
    .doc(channelId)
    .collection("messages")
    .orderBy("timestamp", "desc")
      .startAfter(oldestMessage.timestamp)
      .limit(10)
      .get()
      .then(res =>
        {
          let newMessages = [];

          res.docs.forEach(doc => {
            const messageData = {
              id: doc.id,
              user: UserList[doc.data().userID].username,
              userImage: UserList[doc.data().userID].image,
              ...doc.data(),
            };

            newMessages.push(messageData);
          });

          setMessages(prevMessages => [...prevMessages, ...newMessages]);

        }
      )
      .catch(err => console.log(err));
  };

  
  let isFetchDisabled = false;

  const handleScroll = () => {
    const element = messageContainerRef.current;
    const scrollbarPos = element.clientHeight - element.scrollTop + 5;
   
  
    if (scrollbarPos > element.scrollHeight && !isFetchDisabled) {
      fetchOldMessages();
  
      // Disable fetch for 1 second
      isFetchDisabled = true;
      setTimeout(() => {
        isFetchDisabled = false;
      }, 1000);
    }
  };
  // TO GET OLD MESSAGES 

  return (
    <div class="container-pchat">
      <div class="header">
        <div class="pchannel">
          <div class="pchannel-name">
            {/* You are in Channel - { channel && channel.name} */}
          </div>
          <div class="pchannel-info">
            <img src={channel.photo} alt="" />
            <p>{channel.name}</p>
            {channel?.status ? (
              <StatusButton
                style={{
                  borderRadius: "20px",
                  border: " 2px solid #FFD700",
                  background: "#FFF",
                  width: "auto",
                  maxWidth: "200px",
                }}
                textstyle={{
                  fontSize: "16px",
                  color: "#FFD700",
                  whiteSpace: "nowrap",
                }}
                data={channel?.status}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      <div class="pmessage-container" onScroll={handleScroll} ref={messageContainerRef}>
        {messages.length > 0 &&
          messages.map((data, index) =>
            data.filename === undefined ? (
              <div
                onClick={() => {
                  settaskTitle(data.text);
                  setreportingPersonId(data.userID);
                }}
              >
                <ChatMessage
                  fileP={false}
                  key={data.id}
                  uid={data.userID}
                  text={data.text}
                  name={data.user}
                  image={data.userImage}
                  timestamp={data.timestamp}
                  PersonalChat={true}
                  messageId={data.id}
                  channelId={channelId}
                  reactions={data.reactions}
                  taskTitle={taskTitle}
                  reportingPersonId={reportingPersonId}
                />
              </div>
            ) : (
              <ChatMessage
                fileP={true}
                key={data.id}
                uid={data.userID}
                filename={data.filename}
                download={data.downloadLink}
                name={data.user}
                image={data.userImage}
                timestamp={data.timestamp}
                PersonalChat={true}
                messageId={data.id}
                channelId={channelId}
                reactions={data.reactions}
              />
            )
          )}
             <PinnedMessages Pinnedmessages={Pinnedmessages} PersonalChat={false} channelId={channelId} />
      </div>
      {/* <Modals open={openModal} handleClose={() => setOpenModal(false)} taskInputComponent={<TaskInput text={taskTitle} reportingPerson={reportingPersonId} />} /> */}
      <ChatInput
        sendMessage={sendMessage}
        existingusers={[]}
        PersonalChat={true}
      />
    </div>
  );
};

export default PersonalChat;
