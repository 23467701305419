import React, { useContext, useEffect, useRef, useState } from 'react'
import './Styles/UserMentions.css'
import { useUsersDetails } from "../Context/UsersDetailsContext";
import db from '../firebase';
import Spinner from "./Spinner";

const UserMentions = () => {
  
  const messageContainerRef = useRef(null);
  const [LatestTimeStamp, setLatestTimeStamp] = useState();
  const [messages, setMessages] = useState([])
  const [channelname, setchannelname] = useState('')
  
  const {user,UserList} = useUsersDetails();

  const [showspinner, setshowspinner] = useState(true)

  const getMessageDetails = async (channelId, messageId,unkownmessages,id) => {
    try {

      let message = db.collection("rooms").doc(channelId)
      const channelname = await message.get();
      
     

      if (channelname.exists) {
        message = db.collection("rooms").doc(channelId).collection("messages").doc(messageId);
        const docSnapshot = await message.get();
        setchannelname(channelname.data().name)
        const messageDetails = docSnapshot.data();
        return messageDetails;
      } else {
        unkownmessages.push(id)
        return null; // Ensure a consistent return value when the document does not exist
      }
    } catch (error) {
      console.error("Error getting message details:", error);
      throw error; // Propagate the error to handle it at a higher level
    }
  };



  const removeundefinedIds = (array)=>{

    array.map((id)=>{
      db.collection("MentionedMessages").doc(user.uid).collection('messages').doc(id).delete()

    })
    }




  
  const getMessages = () => {

    db.collection('MentionedMessages').doc(user.uid)
      .collection('messages')
      .orderBy("timestamp", "desc")
      .limit(10)
      .onSnapshot(async (snapshot) => { 
        const mentionedMessages = [];
        const unkownmessages = [];
        
        await Promise.all(snapshot.docs.map(async (doc) => {
          const mentionedMessagesDetail = await getMessageDetails(doc.data().channelID, doc.data().messageID,unkownmessages,doc.id);
          if (mentionedMessagesDetail) {
            mentionedMessages.push(mentionedMessagesDetail);
          }
        }));
        setMessages(mentionedMessages);
        setshowspinner(false)
        removeundefinedIds(unkownmessages)
      });
  };
  
  useEffect(() => {
    setshowspinner(true)
    if (user.uid !== '') {
      getMessages(); // Correct function name to match the definition
    }
  }, [user]);





   // TO CHECK IF TEXT HAS URL OR NOT
   const convertTextToHTML = (text) => {

    const replacementions = replaceMentions(text);
    
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const htmlContent = replacementions.replace(urlRegex, (url) => `<a href="${url}" target="_blank">${url}</a>`);

    return {
      __html: htmlContent,
    };
  };
  // TO CHECK IF TEXT HAS URL OR NOT


  // TO CHECK IF TEXT HAS MENTIONS OR NOT
 function replaceMentions(input) {
  const mentionRegex = /\@\[([^\]]+)\]\(([^\)]+)\)/g;
  return input.replace(mentionRegex, '<span style="color: blue;">@$1</span>');
}
  // TO CHECK IF TEXT HAS MENTIONS OR NOT




  const fetchOldMessages = () => {

    const oldestMessage = messages.reduce((oldest, current) =>
      oldest.timestamp < current.timestamp ? oldest : current
    );


    db.collection('MentionedMessages').doc(user.uid)
      .collection('messages')
      .orderBy("timestamp", "desc")
      .startAfter(oldestMessage.timestamp)
      .limit(10)
      .onSnapshot(async (snapshot) => { 
        const mentionedMessages = [];
        const unkownmessages = [];
        
        await Promise.all(snapshot.docs.map(async (doc) => {
          const mentionedMessagesDetail = await getMessageDetails(doc.data().channelID, doc.data().messageID,unkownmessages,doc.id);
          if (mentionedMessagesDetail) {
            mentionedMessages.push(mentionedMessagesDetail);
          }
        }));
        setMessages(prevMessages => [...prevMessages, ...mentionedMessages]);
        setshowspinner(false)
        removeundefinedIds(unkownmessages)
      });


    // 
    // db.collection("personalMessages")
    // .doc(channelId)
    // .collection("messages")
    // .orderBy("timestamp", "desc")
    //   
    //   .limit(10)
    //   .get()
    //   .then(res =>
    //     {
    //       let newMessages = [];

    //       res.docs.forEach(doc => {
    //         const messageData = {
    //           id: doc.id,
    //           user: UserList[doc.data().userID].username,
    //           userImage: UserList[doc.data().userID].image,
    //           ...doc.data(),
    //         };

    //         newMessages.push(messageData);
    //       });

    //       setMessages(prevMessages => [...prevMessages, ...newMessages]);

    //     }
    //   )
    //   .catch(err => console.log(err));
  };


  
  let isFetchDisabled = false;

  const handleScroll = () => {
    const element = messageContainerRef.current;
    const scrollbarPos = element.clientHeight - element.scrollTop + 5;
   
  
    if (scrollbarPos > element.scrollHeight && !isFetchDisabled) {
      fetchOldMessages();
  
      // Disable fetch for 1 second
      isFetchDisabled = true;
      setTimeout(() => {
        isFetchDisabled = false;
      }, 1000);
    }
  };


  return (
    <>
  {  
   showspinner?

   <Spinner/>
   :
   <div className='UserMentionContainer' onScroll={handleScroll} ref={messageContainerRef}s>
      {
        messages.map((message)=>(
          <div className='MentionedMessage'>

          <div className='mentionedmessageheader'> <span>{UserList[message.userID].username}</span> mentioned you in <span>  #{channelname}</span> </div>

          <div className='Mentionedmessagedetails'>
            <img src={UserList[message.userID].image} alt="" />

            <div className='usermentiontextandname'>
              <div className='mentionedmessageusername'>{UserList[message.userID].username}</div>
              <div className='usermentiontext' dangerouslySetInnerHTML={convertTextToHTML(message.text)}></div>
            </div>
          </div>
          </div>
        ))
      }
      
      
    </div>}
    
    </>
  )
}

export default UserMentions