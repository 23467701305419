import React, { useContext } from 'react'
import styled from 'styled-components'
import { auth, provider } from '../firebase'
import db from '../firebase';
import { useUsersDetails } from "../Context/UsersDetailsContext";
import firebase from 'firebase/compat/app';
import logo from '../Images/Frame 25_result2.png'

function Login({ setcUser }) {


  const { setUser, getuserLists } = useUsersDetails()


  const signIn = () => {
    auth.signInWithPopup(provider)
      .then((result) => {
        const currentTimestamp = firebase.firestore.Timestamp.now().toDate();
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const joinDate = currentTimestamp.toLocaleDateString('en-IN', options);


        const newUser = {
          name: result.user.displayName,
          photo: result.user.photoURL,
          uid: result.user.uid,
          role: "noaccess",
          accessToken: result.credential.accessToken,
          joinDate: joinDate,
          email: result.user.email,
          active_status:"active"
        };


        const userRef = db.collection('userlists').doc(newUser.uid);

        userRef.get().then((docSnapshot) => {
          if (!docSnapshot.exists) {
            userRef.set(newUser)
              .then(() => {
                localStorage.setItem('accesstoken', result.credential.accessToken);
                setUser(newUser);
                setcUser(newUser);
                localStorage.setItem('userId', newUser.uid)
                console.log('New user added to userlists collection:', newUser);
                getuserLists(newUser)
              })
              .catch((error) => {
                console.error('Error adding new user to userlists collection:', error);
              });
          } else {
            const existingUser = docSnapshot.data();
            const updatedUser = {
              ...existingUser,
              name: newUser.name,
              photo: newUser.photo,
              accessToken: newUser.accessToken,
              active_status:"active"
            };

            userRef.update(updatedUser)
              .then(() => {
                localStorage.setItem('accesstoken', result.credential.accessToken);
                setUser(updatedUser);
                setcUser(updatedUser);
                getuserLists(updatedUser)
                localStorage.setItem('userId', updatedUser.uid)
                console.log('User data updated for existing user:', updatedUser);
              })
              .catch((error) => {
                console.error('Error updating existing user data:', error);
              });
          }


        }).catch((error) => {
          console.error('Error checking for existing user:', error);
        });
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  return (<>


    <Content>
      <SignInButton onClick={() => signIn()}>
        Sign In With Google
      </SignInButton>
    </Content>


    <BackgroundImage>
      <img src={logo} style={{
        width: "100%", height: "100dvh", objectFit: "cover",
        objectPosition: "0 20%"
      }} alt="" />
    </BackgroundImage>
  </>
  )
}

export default Login;


const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Send the image to the background */
`;

const Container = styled.div`
    background: url(${require('../Images/Frame 25_result2.png')}) center/cover no-repeat;
`

const Content = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

`

const SlackImg = styled.img`

    height: 100px;

`

const SignInButton = styled.button`
    margin-top: 50dvh;
    background-color: #0a8d48;
    color: white;
    border: none;
    height: 40px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 15px;
`
