import React from 'react'
import { TailSpin } from 'react-loader-spinner';
import './Styles/Spinner.css'

const Spinner = () => {
  return (
    <div className='spinner-container '>
      <TailSpin
        visible={true}
        height="80"
        width="80"
        color="#350D36"
        ariaLabel="tail-spin-loading"
        radius={1}
        wrapperStyle={{}}
        wrapperClass=""
      />

    </div>
  )
}

export default Spinner