import React, { useContext, useEffect, useRef, useState } from "react";
import "./Styles/ChatSideBar.css";
import { RxCross1 } from "react-icons/rx";
import { Context } from "../Context/NoteContext";
import { TfiMoreAlt } from "react-icons/tfi";
import db from "../firebase";
import firebase from "firebase/compat/app";
import { CiEdit } from "react-icons/ci";
import { AiOutlineEdit, AiOutlineCheck } from "react-icons/ai";
import { useUsersDetails } from "../Context/UsersDetailsContext";
const ChatSideBar = ({
  setTranslateX,
  translateX,
  channelId,
  deletechannel,
  existingusers,
  setexistingusers,
  setnewusers,
  newusers,
  adduser,
  channelname,
}) => {
  const userPermissionsRef = useRef();
  const [activeuserid, setactiveuserid] = useState();

  const handleTranslateClick = () => {
    setTranslateX("hidden"); // Adjust the translation amount as needed
  };

  const context = useContext(Context);
  const { user } = useUsersDetails()
  const moveUserTomembers = (userId) => {};

  const mouseenter = (id) => {
    setactiveuserid(id);
  };

  const mouseleave = (id) => {
    setactiveuserid();
  };

  // TO REMOVE THE USER

  const removeUser = (userId, Restricted, joinTime) => {
    const roomRef = db.collection("rooms").doc(channelId);
    if (user.role === "admin" || user.role === "manager") {
      roomRef
        .update({
          members: firebase.firestore.FieldValue.arrayRemove({
            userid: userId,
            isRestricted: Restricted,
            joinTime: joinTime,
          }),
        })
        .then(() => {
          console.log(`User ${userId} removed from group ${channelId}`);
        })
        .catch((error) => {
          console.error("Error removing user from group:", error);
        });
    } else {
      console.log("User is not the owner of the room");
    }
  };

  // TO REMOVE THE USER

  const restrictuser = (userId, isRestricted) => {
    const roomRef = db.collection("rooms").doc(channelId);

    roomRef.get().then((doc) => {
      const roomData = doc.data();
      const members = roomData.members || [];

      const userIndex = members.findIndex((member) => member.userid === userId);

      if (userIndex !== -1) {
        members[userIndex].isRestricted = true;

        roomRef.update({
          members: members,
        });
      } else {
        console.log(`User ${userId} not found in room ${channelId}`);
      }
    });
  };

  const permituser = (userId, isRestricted) => {
    const roomRef = db.collection("rooms").doc(channelId);

    roomRef.get().then((doc) => {
      const roomData = doc.data();
      const members = roomData.members || [];

      const userIndex = members.findIndex((member) => member.userid === userId);

      if (userIndex !== -1) {
        members[userIndex].isRestricted = false;

        roomRef.update({
          members: members,
        });
      } else {
        console.log(`User ${userId} not found in room ${channelId}`);
      }
    });
  };

  // EDIT CHANNEL FUNCTIONS
  const channelnameRef = useRef(null);
  const [editable, setEditable] = useState(false);
  const [channelnamevalue, setchannelnamevalue] = useState(channelname);

  useEffect(() => {
    // Focus on the input when editable becomes true
    if (editable && channelnameRef.current) {
      channelnameRef.current.focus();
    }
  }, [editable]);


  const handleEditClick = () => {
    setEditable(true);
    
  };

  const handleSaveClick = () => {
    updateChannelName(channelnamevalue);
    setEditable(false);
  };
  const handleKeydown = (e) => {
    if (e.key === "Enter") {
      updateChannelName(channelnamevalue);
      setEditable(false);
    }
  };

  const updateChannelName = (newName) => {
    if (channelId) {
      db.collection("rooms")
        .doc(channelId)
        .update({ name: newName })
        .then(() => {
          console.log("Channel name updated successfully!");
        })
        .catch((error) => {
          console.error("Error updating channel name: ", error);
        });
    }
  };

  const handleInputChange = (e) => {
    setchannelnamevalue(e.target.value);
  };
  // EDIT CHANNEL FUNCTIONS

  return (
    <div className="chat-sidebar-container" style={{ visibility: translateX }}>
      <div
        className="crossback"
        onClick={() => {
          handleTranslateClick();
        }}
      ></div>

      <div className="chat-sidebar">
        <div className="cross">
          <RxCross1
            onClick={() => {
              handleTranslateClick();
            }}
            className="svg"
          />
        </div>

        <div className="input-container-chatsidebar">
          <input
            type="text"
            value={channelnamevalue}
            onChange={handleInputChange}
            disabled={!editable}
            ref={channelnameRef}
            onKeyDown={handleKeydown}
            className={`input-field ${editable ? "editable" : ""}`}
          />
          {(user.role === "admin" || user.role === "manager") &&
            (editable ? (
              <AiOutlineCheck
                className="icon-editchannel save-icon-editchannel"
                onClick={handleSaveClick}
              />
            ) : (
              <AiOutlineEdit
                className="icon-editchannel edit-icon-editchannel"
                onClick={handleEditClick}
              />
            ))}
        </div>

        <div className="members-section">
          <div className="members-header">Members</div>
          {existingusers.map((member, index) => {
            return (
              <div key={index} className="member">
                <div className="userinfo">
                  <img src={member.image} alt="User 1" />
                  <span>{member.username}</span>
                </div>

                <div
                  onMouseEnter={() => {
                    mouseenter(member.uid);
                  }}
                  onMouseLeave={() => {
                    mouseleave(member.uid);
                  }}
                  className={`${
                    user.role === "admin" || user.role === "manager"
                      ? "user-more"
                      : "user-permissions-hide"
                  }`}
                >
                  <TfiMoreAlt />

                  <div
                    className={`${
                      activeuserid === member.uid
                        ? "user-permissions"
                        : "user-permissions-hide"
                    } `}
                  >
                    <p
                      onClick={() => {
                        removeUser(
                          member.uid,
                          member.isRestricted,
                          member.joinTime
                        );
                      }}
                    >
                      Remove{" "}
                    </p>
                    {member.isRestricted === false ? (
                      <p
                        onClick={() => {
                          restrictuser(member.uid, member.isRestricted);
                        }}
                      >
                        Restrict
                      </p>
                    ) : (
                      <p
                        onClick={() => {
                          permituser(member.uid, member.isRestricted);
                        }}
                      >
                        Permit
                      </p>
                    )}
                  </div>
                </div>
              </div>
            );
          })}

          {/* TO ADD MEMBERS  */}
          <div className="members-header-new">Add New Users</div>
          {newusers.map((user, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  adduser(user.uid, user.role);
                  moveUserTomembers(user.uid);
                }}
                className="member"
              >
                <div className="userinfo">
                  <img src={user.image} alt="User 1" />
                  <span>{user.username}</span>
                </div>
              </div>
            );
          })}
          {/* TO ADD MEMBERS  */}
        </div>

        {user.role === "admin" && (
          <button
            onClick={() => {
              deletechannel();
            }}
            className="delete-channel-btn"
          >
            Delete Channel
          </button>
        )}
      </div>
    </div>
  );
};

export default ChatSideBar;
