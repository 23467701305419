import React, { useState } from 'react';
import { FaCheckCircle, FaTimes } from 'react-icons/fa';
import { RiErrorWarningFill } from 'react-icons/ri';
import { IoWarning } from 'react-icons/io5';
import { useContext } from 'react';
import { Context } from '../Context/NoteContext';
import './Styles/ChatnTaskLoader.css'
import { AiFillWarning } from "react-icons/ai";
import logo from '../Images/Frame 25_result2.png'

const ChatnTaskLoader = () => {

  const context = useContext(Context);
  const { Loader, setLoader } = context;


  //   if (!Loader) {
  //     return null; 
  //   }


  return (
    <div className={`${Loader ? 'Loader-container' : 'hidecontainer'}`} >

      {/* <div className='Loader-box'> */}

      <img src={logo} style={{
        width: "100%", height: "100%", objectFit: "cover",
        objectPosition: "0 20%"
      }} alt="" />

      {/* <div className='Loader-head'>Chat n Task</div> */}






      {/* </div> */}

    </div>
  )
}

export default ChatnTaskLoader