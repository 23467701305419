import React, { useContext, useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableVirtuoso } from 'react-virtuoso';
import Button from '@mui/material/Button';
import { Context } from '../Context/NoteContext';

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table {...props} sx={{ borderCollapse: 'collapse', backgroundColor: 'white', '& th': { backgroundColor: '#f7f7f7' }, '& td, & th': { border: '1px solid #dbdbdb', padding: '3px 5px' }, '& .compact td, & .compact th': { padding: '5px', fontSize: '14px' } }} />
  ),
  TableHead,
  TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
  TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};

const columns = [
  {
    width: 1,
    label: 'TaskId',
    dataKey: 'task_id',
  },
  {
    width: 50,
    label: 'Title',
    dataKey: 'title',
  },
  {
    width: 50,
    label: 'Description',
    dataKey: 'description',
  },
  {
    width: 5,
    label: 'Due',
    dataKey: 'due_date',
  },
  {
    width: 5,
    label: 'Tag',
    dataKey: 'tag',
  },
  {
    width: 2,
    label: 'Status',
    dataKey: 'status',
  },
  {
    width: 15,
    label: 'Assigned',
    dataKey: 'assigned_name',
  },
  {
    width: 15,
    label: 'Reporting',
    dataKey: 'reporting_name',
  },
  {
    width: 5,
    label: 'Action',
    dataKey: 'View',
  },
];

const ReactVirtualizedTable = ({ data, setdetail, setopenComment }) => {
  const context = useContext(Context);
  const { userlists } = context;
  useEffect(() => {
    const updatedRows = data?.map((row, index) => {
      const assignedUser = userlists.find((user) => user.userId === row.assigned_to);
      const reportingUser = userlists.find((user) => user.userId === row.reporting_person);
      // console.log(assignedUser)
      return {
        ...row,
        View: "View",
        assigned_name: assignedUser?.username,
        reporting_name: reportingUser?.username,
      };
    });

    setRows(updatedRows);
  }, [data, userlists]);



  const [rows, setRows] = useState(data);

  const handleViewClick = (rowData) => {
    // console.log(rowData);
    setdetail(rowData)
    setopenComment(true)
    // Add any additional logic or actions you want to perform on view click
  };
  const formatDueDate = (dueDate) => {
    const dateObject = new Date(dueDate);
    return dateObject.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  };
  function fixedHeaderContent() {
    return (
      <TableRow>
        {columns?.map((column, index) => (
          <TableCell
            key={column.dataKey}
            variant="head"
            align='center'
            sx={{ backgroundColor: "white" }}

          >
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    );
  }

  function rowContent(_index, row) {
    return (
      <React.Fragment>
        {columns?.map((column, index) => (
          <TableCell
            key={column.dataKey}
            align='center'
            sx={{ backgroundColor: "white", whiteSpace: "pre-wrap" }}
          >
            {column?.dataKey === 'View' ? (
              <Button variant="contained" size="small" onClick={() => handleViewClick(row)}>{row[column?.dataKey]}</Button>
            ) : column?.dataKey === 'tag' && Array.isArray(row?.tag) ? (
              row?.tag?.map((tag, tagIndex) => (
                <div key={tagIndex}>{tag}</div>
              ))
            ) : column?.dataKey === 'due_date' ? (row[column?.dataKey] == null ? "" :
              formatDueDate(row[column?.dataKey])
            ) : (
              row[column?.dataKey] == null ? "" : row[column?.dataKey]
            )}
          </TableCell>
        ))}
      </React.Fragment>
    );
  }

  return (
    <Paper style={{
      height: "60vh", width: '1000px', marginLeft: "20px"
    }}>
      <TableVirtuoso
        data={rows}
        components={VirtuosoTableComponents}
        fixedHeaderContent={fixedHeaderContent}
        itemContent={rowContent}
      />
    </Paper >
  );
};

export default ReactVirtualizedTable;
