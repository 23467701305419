import React, { useState, useContext } from "react";
import StatusButton from "./Common/StatusButton";
import styled from "styled-components";
import ReactVirtualizedTable from "./Tablecomponent";
import { useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import db from "../firebase";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import { Context } from "../Context/NoteContext";
import UseCompletedTasks from "./UseCompletedTasks";
import useallCompletedTasks from "./UseallCompleteTask";

const TableTask = ({ setdetail, setopenComment }) => {
    const [datas, setdatas] = useState([]);
    const [filter, setfilter] = useState([]);
    const [sort, setsort] = useState("");
    const [statusValues, setStatusValues] = useState("Pending");
    const [tabValues, setTabValues] = useState("assigned_to");
    const [dueSortAscending, setDueSortAscending] = useState(true);
    const [countTab1, setcountTab1] = useState("");
    const [countTab2, setcountTab2] = useState("");
    const [countTab3, setcountTab3] = useState("");
    const [countTaball, setcountTaball] = useState("")
    const [allData, setallData] = useState([])
    // const [completeTasksData, setcompleteTasksData] = useState([])

    const [fetchComplete, setfetchComplete] = useState(false);
    const [allfetchComplete, setallfetchComplete] = useState(false);

    const userId = localStorage.getItem("userId");

    const context = useContext(Context);
    const { taskUpdate, settaskUpdate } = context;

    const { tasks: completedTasks, fetchNext: fetchNextCompletedTasks } =
        UseCompletedTasks({
            enabled: fetchComplete
        });

    const { tasks: allcompletedTasks, fetchNext: fetchNextallCompletedTasks } =
        useallCompletedTasks({
            enabled: allfetchComplete
        });
    useEffect(() => {
        return () => {
            db.collection("Task")
                .where("assigned_to", "==", localStorage.getItem("userId"))
                .where("status", "==", statusValues)
                .onSnapshot((snapshot) => {
                    const tasksData = snapshot.docs?.map((doc) => ({
                        id: doc.id,
                        ...doc.data(),
                    }));
                    setfilter(tasksData);
                });
        };
    }, [])

    let userRole = localStorage.getItem('userRole')

    const fetchallTask = async () => {
        if (userRole === "admin") {
            try {
                const snapshot = await db.collection('Task').where("status", "in", ["Pending", "Running"]).get();
                const tasksData = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                setdatas(tasksData);
                setallfetchComplete(true)
                if (statusValues != "all")
                    setcountTaball(tasksData?.filter(data => data.status === statusValues).length);
                else
                    setcountTaball(tasksData?.length);
            }
            catch (error) {
                console.error('Error fetching tasks:', error);
            }
        }
    };

    useEffect(() => {
        fetchallTask();
        settaskUpdate(false)
    }, [taskUpdate]);


    useEffect(() => {
        const taskQuery1 = db.collection("Task").where("assigned_to", "==", userId).where("status", "in", ["Pending", "Running"]).get();
        const taskQuery2 = db.collection("Task").where("reporting_person", "==", userId).where("status", "in", ["Pending", "Running"]).get();
        const taskQuery3 = db.collection("Task").where("created_by", "==", userId).where("status", "in", ["Pending", "Running"]).get();
        settaskUpdate(false)

        Promise.all([taskQuery1, taskQuery2, taskQuery3])
            .then((querySnapshots) => {
                let tasksData = [];
                let uniqueTaskIds = new Set();

                querySnapshots.forEach((snapshot) => {
                    snapshot.docs.forEach((doc) => {
                        const taskId = doc.id;
                        if (!uniqueTaskIds.has(taskId)) {
                            uniqueTaskIds.add(taskId);
                            tasksData.push({
                                id: taskId,
                                ...doc.data(),
                            });
                        }
                    });

                });
                if (statusValues != "Complete") {
                    setcountTab1(tasksData?.filter((task) => {
                        if (statusValues !== "all") return task.assigned_to === userId && task.status === statusValues;
                        return task.assigned_to === userId;
                    })?.length);
                    setcountTab2(tasksData?.filter((task) => {
                        if (statusValues !== "all") return task.reporting_person === userId && task.status === statusValues;
                        return task.reporting_person === userId;
                    })?.length
                    );
                    setcountTab3(tasksData?.filter((task) => {
                        if (statusValues !== "all") return task.created_by === userId && task.status === statusValues;
                        return task.created_by === userId;
                    })?.length
                    );
                }
                setallData(tasksData)
                setfetchComplete(true)
                let newData;
                if (tabValues !== "all") { newData = tasksData?.filter((data) => data[tabValues] === userId); }
                else { newData = datas }

                if (statusValues != "all")
                    setfilter(newData?.filter((data) => data?.status === statusValues))
                else
                    setfilter(newData)
            })
            .catch((error) => {
                console.error("Error fetching tasks:", error);
            });
    }, [taskUpdate]);

    useEffect(() => {
        updateCompletedCounts(tabValues, statusValues);
    }, [allcompletedTasks]);


    const updateCompletedCounts = (tabValues, statusValues) => {
        if (statusValues === "Complete") {
            if (tabValues !== "all")
                setfilter(completedTasks[tabValues]);
            else
                setfilter(allcompletedTasks)
            setcountTab1(completedTasks.assigned_to?.length);
            setcountTab2(completedTasks.reporting_person?.length);
            setcountTab3(completedTasks.created_by?.length);
            setcountTaball(allcompletedTasks?.length);

        }
    };
    useEffect(() => {
        updateCompletedCounts(tabValues, statusValues);
    }, [completedTasks.reporting_person, completedTasks.assigned_to, completedTasks.created_by]);

    const statusHandler = (e) => {
        setfetchComplete(false)
        setallfetchComplete(false)
        setStatusValues(e.target.id);
        const userId = localStorage.getItem("userId");
        let query;
        if (tabValues !== "all") {
            if (e.target.id === "all")
                query = db.collection("Task").where(tabValues, "==", userId).where("status", "in", ["Pending", "Running"]);
            else
                query = db.collection("Task").where(tabValues, "==", userId).where("status", "==", e.target.id);

            // db.collection("Task");
            if (e.target.id !== "Complete") {
                query.onSnapshot((snapshot) => {
                    const tasksData = snapshot.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data(),
                    }));
                    setfilter(tasksData)
                });
            }
            else {
                updateCompletedCounts(tabValues, e.target.id);
            }
            if (e.target.id != "Complete") {
                let filteredData;
                if (e.target.id != "all") {
                    filteredData = allData?.filter((data) => data?.status === e.target.id);
                    setcountTaball(datas?.filter((value) => value.status === e.target.id)?.length)
                }
                else {
                    filteredData = allData;
                    setcountTaball(datas?.length)
                }

                setcountTab1((filteredData?.filter((data) => data?.assigned_to === userId)?.length))
                setcountTab2((filteredData?.filter((data) => data?.reporting_person === userId)?.length))
                setcountTab3(filteredData?.filter((data) => data?.created_by === userId)?.length)
            }
        }
        else {
            if (e.target.id != "all") {
                if (e.target.id !== "Complete") {
                    setcountTaball(datas?.filter((value) => value.status === e.target.id)?.length)
                    setcountTab1((datas?.filter((data) => data?.assigned_to === userId && data?.status === e.target.id)?.length))
                    setcountTab2((datas?.filter((data) => data?.reporting_person === userId && data?.status === e.target.id)?.length))
                    setcountTab3(datas?.filter((data) => data?.created_by === userId && data?.status === e.target.id)?.length)
                    setfilter(datas?.filter((value) => value.status === e.target.id))
                }
                else {
                    // setfilter(allcompletedTasks);
                    updateCompletedCounts(tabValues, e.target.id);

                }
            }
            else {
                setfilter(datas)
                setcountTaball(datas?.length)
                setcountTab1((datas?.filter((data) => data?.assigned_to === userId)?.length))
                setcountTab2((datas?.filter((data) => data?.reporting_person === userId)?.length))
                setcountTab3(datas?.filter((data) => data?.created_by === userId)?.length)
            }
        }
    };

    const handleNext = async () => {
        await fetchNextCompletedTasks();
        await fetchNextallCompletedTasks();
    }

    const handleSort = (type) => {
        let sortedData;
        if (type === "due") {
            sortedData = [...filter].sort((a, b) => {
                const dateA = new Date(a.due_date);
                const dateB = new Date(b.due_date);
                if (dueSortAscending)
                    return dateA - dateB;
                else
                    return dateB - dateA;
            });
            setDueSortAscending((prevState) => !prevState);
        }
        else if (type === "priority")
            sortedData = [...filter].sort((a, b) => parseInt(b.priority) - parseInt(a.priority));

        setfilter(sortedData);
        setsort(type);
    };

    const tabHandler = (event, newValue) => {
        setTabValues(newValue);
        const userId = localStorage.getItem("userId");
        let filteredData;
        if (newValue != "all") {
            if (statusValues !== "Complete") {
                if (statusValues != "all")
                    filteredData = allData?.filter((data) => data?.status === statusValues);
                else
                    filteredData = allData;
                if (newValue === "assigned_to") {
                    filteredData = filteredData?.filter((data) => data?.assigned_to === userId);
                    setcountTab1(filteredData?.length)
                } else if (newValue === "reporting_person") {
                    filteredData = filteredData?.filter((data) => data?.reporting_person === userId);
                    setcountTab2(filteredData?.length)
                } else if (newValue === "created_by") {
                    filteredData = filteredData?.filter((data) => data?.created_by === userId);
                    setcountTab3(filteredData?.length)
                }
                setfilter(filteredData);

            }
            else
                updateCompletedCounts(newValue, statusValues);
        }
        else {
            if (statusValues !== "all") {
                const newdata = datas.filter((value) => value.status === statusValues);
                setcountTaball(newdata?.length);
                if (statusValues === "Complete")
                    updateCompletedCounts(newValue, statusValues);
                else
                    setfilter(newdata);
            }
            else {
                setcountTaball(datas?.length);
                setfilter(datas)
            }
        }
    };

    return (
        <div style={{ marginLeft: "10px", height: "86vh" }}>
            <TagSection>
                <StatusButton
                    style={{
                        borderRadius: "20px",
                        border: statusValues === "all" ? "0px" : " 1px solid #000",
                        background: statusValues === "all" ? "#085FE2" : "#FFF",
                    }}
                    textstyle={{
                        fontWeight: 700,
                        color: statusValues === "all" ? "#FFF" : "#4F4F4F",
                    }}
                    data={"All"}
                    id="all"
                    onClick={statusHandler}
                />
                <StatusButton
                    style={{
                        borderRadius: "20px",
                        border: statusValues === "Pending" ? "0px" : " 1px solid #000",
                        background: statusValues === "Pending" ? "#085FE2" : "#FFF",
                    }}
                    textstyle={{
                        fontWeight: 700,
                        color: statusValues === "Pending" ? "#FFF" : "#4F4F4F",
                    }}
                    data={"Pending"}
                    id="Pending"
                    onClick={statusHandler}
                />
                <StatusButton
                    style={{
                        borderRadius: "20px",
                        border: statusValues === "Complete" ? "0px" : " 1px solid #000",
                        background: statusValues === "Complete" ? "#085FE2" : "#FFF",
                    }}
                    textstyle={{
                        fontWeight: 700,
                        color: statusValues === "Complete" ? "#FFF" : "#4F4F4F",
                    }}
                    data={"Completed"}
                    id="Complete"
                    onClick={statusHandler}
                />
                <StatusButton
                    style={{
                        borderRadius: "20px",
                        border: statusValues === "Running" ? "0px" : " 1px solid #000",
                        background: statusValues === "Running" ? "#085FE2" : "#FFF",
                    }}
                    textstyle={{
                        fontWeight: 700,
                        color: statusValues === "Running" ? "#FFF" : "#4F4F4F",
                    }}
                    data={"Running "}
                    id="Running"
                    onClick={statusHandler}
                />
            </TagSection>
            <Tabs
                value={tabValues}
                onChange={tabHandler}
                aria-label="wrapped label tabs example"
            >
                {userRole === "admin" ? <Tab label={`All (${countTaball})`} value="all" /> : ""}
                <Tab label={`Assigned to me (${countTab1})`} value="assigned_to" />
                <Tab
                    label={`Reporting to me (${countTab2}) `}
                    value="reporting_person"
                />
                <Tab label={`Created by me(${countTab3})`} value="created_by" />
            </Tabs>
            <TagSection style={{ marginLeft: "-10px", marginBottom: "5px" }}>
                <StatusButton
                    style={{ borderRadius: "0px", background: "#FFF" }}
                    textstyle={{ fontWeight: 700, color: "#4F4F4F" }}
                    data={"Sort By"}
                />
                <StatusButton
                    style={{
                        borderRadius: "20px",
                        border: sort === "due" ? "0px" : " 1px solid #000",
                        background: sort === "due" ? "#085FE2" : "#FFF",
                    }}
                    textstyle={{
                        fontWeight: 700,
                        color: sort === "due" ? "#FFF" : "#4F4F4F",
                    }}
                    data={
                        sort === "due" ? (
                            dueSortAscending ? (
                                <>
                                    Date <FaArrowDown />
                                </>
                            ) : (
                                <>
                                    Date <FaArrowUp />
                                </>
                            )
                        ) : (
                            "Date"
                        )
                    }
                    id="due"
                    onClick={() => handleSort("due")}
                ></StatusButton>
                <StatusButton
                    style={{
                        borderRadius: "20px",
                        border: sort === "priority" ? "0px" : " 1px solid #000",
                        background: sort === "priority" ? "#085FE2" : "#FFF",
                    }}
                    textstyle={{
                        fontWeight: 700,
                        color: sort === "priority" ? "#FFF" : "#4F4F4F",
                    }}
                    data={"Priority"}
                    id="priority"
                    onClick={() => handleSort("priority")}
                />
            </TagSection>

            <ReactVirtualizedTable
                data={filter}
                setdetail={setdetail}
                setopenComment={setopenComment}
            />
            {statusValues === "Complete" ? <div className="Task-pagination" style={{ display: 'flex', justifyContent: 'center', paddingLeft: '20px', marginTop: '5px', cursor: "pointer" }}>
                <div style={{ padding: "5px 14px", color: 'white', borderRadius: '5px', fontWeight: '600', backgroundColor: '#085FE2' }} onClick={handleNext}>Next</div>
            </div> : ""
            }

        </div>
    );
};

export default TableTask;
const TagSection = styled.div`
  display: flex;
  margin-top: 1px;
  justify-content: flex-start;
`;
