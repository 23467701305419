import { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import Input from "./Common/Input";
import StatusButton from "./Common/StatusButton";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import db from "../firebase";
import firebase from "firebase/compat/app";
import { Context } from "../Context/NoteContext";
import { Paper } from "@mui/material";
import { TextareaAutosize } from "@mui/base";

const TaskInput = ({ text, reportingPerson }) => {
  const context = useContext(Context);
  const [usertag, setusertag] = useState([])
  const { userlists, setOpenModal, settaskUpdate } = context;
  const topOptions = [{ tag: "Tag 1" }, { tag: "Tag 2" }, { tag: "Tag 3" }];
  useEffect(() => {
    return () => {
      const usertagList = userlists.filter((data =>
        data?.userId === localStorage.getItem("userId")
      ))
      console.log(text)
      setusertag(usertagList)
      // db.collection('task_tags')
      //   .onSnapshot((snapshot) => {
      //     const tasksData = snapshot.docs?.map((doc) => ({
      //       id: doc.id,
      //       ...doc.data(),
      //     }));
      //     const updatedUserTagList = usertagList.map(userTag => {
      //       const allTags = tasksData.reduce((acc, taskData) => acc.concat(taskData.tag), []);
      //       return {
      //         ...userTag,
      //         tag: [...userTag.tag, ...allTags] // Combine previous tags with all tags from tasksData
      //       };
      //     });

      //     console.log(updatedUserTagList)
      //     setusertag(updatedUserTagList);
      //   });

    };
  }, [])
  const priorityValue = [
    { priority: "1" },
    { priority: "2" },
    { priority: "3" },
    { priority: "4" },
    { priority: "5" },
  ];
  const [taskData, settaskData] = useState({
    tags: [],
    assigned_to: "",
    reporting_person: { userId: reportingPerson, username: userlists.find((user) => user.userId === reportingPerson)?.username },
    due_date: "",
    priority: "",
    title: text,
    description: "",
  });
  const handleDueDateChange = (e) => {
    const { name, value } = e.target;
    settaskData({
      ...taskData,
      [name]: value,
    });
    console.log(taskData);
  };

  // Concatenate topOptions with tags from usertagList
  const allOptions = topOptions.concat(usertag.map(userTag => userTag.tag).flat());

  const handleInputChange = (event, newValues) => {
    console.log(newValues);
    if (Array.isArray(newValues)) {
      const tags = newValues.map((newValue) =>
        newValue && newValue.inputValue ? newValue.inputValue : newValue.tag
      );
      const uniqueTags = [...new Set(tags)];

      settaskData({ ...taskData, tags: uniqueTags });
    }
  };
  const filter = createFilterOptions();
  const filterOptions = (options, params) => {
    const filtered = filter(options, params);

    const { inputValue } = params;
    const isExisting = options.some((option) => inputValue === option.tag);
    if (inputValue !== "" && !isExisting) {
      filtered.push({
        inputValue,
        tag: `Add "${inputValue}"`,
      });
    }
    return filtered;
  };

  const addTaskHandler = async () => {
    const user = localStorage.getItem("userId");
    setOpenModal(false);
    console.log(taskData);
    // const currentTimestamp = firebase.firestore.Timestamp.now().toDate();
    // const options = { year: 'numeric', month: 'long', day: 'numeric' };

    const tasksRef = db.collection("Task");


    const taskCount = await db.collection('config').doc('total_number_of_tasks').get().then(doc => doc.data().total_number_of_tasks);

    const taskId = parseInt(Number(taskCount) + 1);


    db.collection('config').doc('total_number_of_tasks').set({
      total_number_of_tasks: taskId
    }, { merge: true });

    let payload = {
      task_id: taskId,
      created_by: user,
      created_on_tt: firebase.firestore.Timestamp.now(),
      tag: taskData?.tags,
      assigned_to: taskData.assigned_to?.userId || null,
      reporting_person: taskData.reporting_person?.userId || null,
      due_date: taskData?.due_date || null,
      priority: taskData.priority?.priority || null,
      title: taskData?.title || null,
      description: taskData?.description || null,
      status: "Pending",
    };

    console.log(payload);
    settaskUpdate(prevState => !prevState)
    return tasksRef.add(payload);

  };

  return (
    <div>
      <Main>
        <Paper elevation={6} sx={{ padding: "15px" }}>
          <Input
            placeholder="Type title here"
            name="title"
            value={taskData.title}
            style={{ height: "25px", width: "355px", marginTop: "14px" }}
            onChange={handleDueDateChange}
          />
          <Input
            placeholder="Description..."
            name="description"
            value={taskData.description}
            style={{ height: "30px", width: "355px", marginTop: "10px" }}
            onChange={handleDueDateChange}
          />
          <TagSection>
            <Autocomplete
              multiple
              value={taskData.tag}
              onChange={handleInputChange}
              filterOptions={filterOptions}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={allOptions}
              getOptionLabel={(option) =>
                typeof option === "string"
                  ? option
                  : option.inputValue || option.tag || ""
              }
              renderOption={(props, option) => <li {...props}>{option.tag}</li>}
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ minWidth: "200px" }}
                  size="small"
                  label="Add Tags"
                />
              )}
            />

            <Autocomplete
              value={settaskData.priority}
              onChange={(event, newValue) => {
                settaskData({ ...taskData, priority: newValue });
              }}
              options={priorityValue}
              renderOption={(props, option) => (
                <li {...props}>{option.priority}</li>
              )}
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.priority
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ width: "120px" }}
                  label="Priority"
                  size="small"
                />
              )}
            />
            {/* </Status> */}
          </TagSection>
          <TagSection>
            <TextField
              label="due date"
              name="due_date"
              value={taskData.due_date}
              onChange={handleDueDateChange}
              size="small"
              sx={{ backgroundColor: "white" }}
              type="date"
              InputLabelProps={{ shrink: true }}
            />
          </TagSection>

          <TagSection>
            <Autocomplete
              value={taskData.assigned_to}
              onChange={(event, newValue) =>
                settaskData({
                  ...taskData,
                  assigned_to: newValue,
                })
              }
              options={userlists}
              getOptionLabel={(option) => option.username || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ width: "220px" }}
                  label="Assigned To"
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </TagSection>
          <TagSection>
            <Autocomplete
              id="assigned-to-autocomplete"
              value={taskData.reporting_person}
              onChange={(event, newValue) =>
                settaskData({
                  ...taskData,
                  reporting_person: newValue,
                })
              }
              options={userlists}
              selectOnFocus
              getOptionLabel={(option) => option.username || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ width: "220px" }}
                  label="Reporting to"
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </TagSection>
          <ButtonSection>
            <StatusButton
              style={{
                background: "#085FE2",
                width: "140.158px",
                cursor: "pointer",
              }}
              data={"Submit"}
              onClick={addTaskHandler}
            />
          </ButtonSection>
        </Paper>
      </Main>
    </div>
  );
};

export default TaskInput;

const Main = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
`;

const TagSection = styled.div`
  display: flex;
  margin-top: 14px;
  flex-direction: coloumn;
  justify-content: space-between;
`;



const ButtonSection = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
`;

