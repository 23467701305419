import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import { IoIosAddCircleOutline } from 'react-icons/io';
import { FaPlus } from 'react-icons/fa';
import { sidebarItemsData } from '../data/SidebarData'
import db from '../firebase'
import { useLocation, useNavigate } from 'react-router-dom'
import './Styles/Sidebar.css'
import { BsPeople } from 'react-icons/bs';
import { Context } from '../Context/NoteContext';
import firebase from 'firebase/compat/app';
import { BsGrid1X2Fill } from 'react-icons/bs';
import { FaBookmark } from 'react-icons/fa'
import logo from '../Images/SparkLogo.svg'
import { FaUser } from "react-icons/fa";
import TaskInput from './TaskInput';
import Modals from './Common/Models';
import { VscMention } from "react-icons/vsc";
import { CgNotes } from "react-icons/cg";
import { TbBuildingBroadcastTower } from "react-icons/tb";
function Sidebar(props) {



    const context = useContext(Context);
    const [taskCount, settaskCount] = useState(0)
    const { usersChatRooms, rooms, user, admin, openModal,AddNote, setopenNotesModal,setOpenModal,setactiveunreadmessagesCount } = context;

    const navigate = useNavigate();

    const goToChannel = (item) => {
        if (item.id) {
            navigate(`/room/${item.id}`)
            setactiveunreadmessagesCount(item.unreadmessages)
        }

    }

    useEffect(() => {
        return () => {
            db.collection("Task")
                .where("assigned_to", "==", localStorage.getItem("userId"))
                .where("status", "==", "Pending")
                .onSnapshot((snapshot) => {
                    const tasksData = snapshot.docs?.map((doc) => ({
                        id: doc.id,
                        ...doc.data(),
                    }));
                    console.log("pending data", tasksData);

                    settaskCount(tasksData?.length);
                });
        };
    }, [])

    const goToDM = (id) => {

        if (id) {
            navigate(`/personalroom/${id}`)
        }
    }


    const addChannel = () => {
        const promptName = prompt("Enter channel name");
        if (promptName) {

            const alladmins = []

            admin.forEach(adminData => {
                alladmins.push({
                    userid: adminData.userId,
                    isRestricted: false,
                    joinTime: firebase.firestore.Timestamp.now(),
                });
            });


            db.collection('rooms').add({
                name: promptName,
                Creater: user.uid,
                members: [
                    {
                        userid: user.uid,
                        isRestricted: false,
                        joinTime: firebase.firestore.Timestamp.now(),
                    },
                    ...alladmins
                ],
                PinnedMessages:[]
            })



        }
    }

    return (
        <>
     
        <div className='sidebarwidth'>

        </div>
       
        <div className="container-sidebar">
            <div className="workspace-container-sidebar">
               
                    <img src={logo} className="new-message-sidebar" alt="" />
                
            </div>

            <div className='scroll-sidebar' >

                <div className="main-channels-sidebar">
                    {/* {
                        sidebarItemsData.map(item => (
                            <div key={item.text} className='main-channel-item-sidebar'>
                                {item.icon}
                                {item.text}

                            </div>
                        ))
                    } */}
                    <div className={`${window.location.pathname === '/Task' ? 'active-pathSidebar' : 'main-channel-item-sidebar'}  `} onClick={() => { navigate('/Task') }} >
                        <BsGrid1X2Fill />
                        <div className='task-container-sidebar'>
                            Task
                            {taskCount > 0 && <span style={{ marginLeft: "100px" }} className='unread-messages'>{taskCount > 99 ? '99+' : taskCount}</span>}
                            <FaPlus onClick={() => setOpenModal(true)} />
                        </div>
                    </div>
                    <div className={`${window.location.pathname === '/Bookmark' ? 'active-pathSidebar' : 'main-channel-item-sidebar'}  `} onClick={() => { navigate('/Bookmark') }} >
                        <FaBookmark />
                        Bookmarks
                    </div>
                    <div className={`${window.location.pathname === '/Notes' ? 'active-pathSidebar' : 'main-channel-item-sidebar'}  `} onClick={() => { navigate('/Notes') }} >
                        <CgNotes />
                        <div className='task-container-sidebar'>
                            Notes
                            {taskCount > 0 && <span style={{ marginLeft: "100px" }} className='unread-messages'>{taskCount > 99 ? '99+' : taskCount}</span>}
                            <FaPlus onClick={() => AddNote()} />
                        </div>

                    </div>
                    <div className={`${window.location.pathname === '/Mentions' ? 'active-pathSidebar' : 'main-channel-item-sidebar'}  `} onClick={() => { navigate('/Mentions') }} >
                       <span>@</span>
                        Mentions
                    </div>
                    <div className={`${window.location.pathname === '/UserProfile' ? 'active-pathSidebar' : 'main-channel-item-sidebar'}  `} onClick={() => { navigate('/UserProfile') }} >
                        <FaUser />
                        Profile
                    </div>
                   {(user.role==='admin' || user.role==='manager' )&& <div className={`${window.location.pathname === '/Broadcast' ? 'active-pathSidebar' : 'main-channel-item-sidebar'}   `} onClick={() => { navigate('/Broadcast') }} >
                        <TbBuildingBroadcastTower />
                        Broadcast
                    </div>}
                    <div className={`${window.location.pathname === '/Users' ? 'active-pathSidebar' : 'main-channel-item-sidebar'}   `} onClick={() => { navigate('/Users') }} >
                        <BsPeople />
                        UserList
                    </div>
                </div>

                <div className="channels-container-sidebar">

                    <div className="new-channel-container-sidebar ">
                        <div className='channel-header'>
                            Channels
                        </div>

                        {
                            user.role === 'admin' ?
                                <FaPlus onClick={addChannel} /> :
                                user.role === 'manager' ?
                                    <FaPlus onClick={addChannel} /> :
                                    ''
                        }
                    </div>

                    <div className="channels-list-sidebar">
                        {
                            rooms.map(item => (
                                <div key={item.id} className={`${window.location.pathname.split('/')[2] === item.id ? 'channel-sidebar-active' : 'channel-sidebar'}`} onClick={() => goToChannel(item)}>

                                    <span>{item.name}</span>
                                    {item.unreadmessages > 0 && <span className='unread-messages'>{item.unreadmessages > 99 ? '99+' : item.unreadmessages}</span>}

                                </div>
                            ))
                        }
                    </div>
                </div>


                <div className="channels-container-sidebar">
                    <div className="new-channel-container-sidebar">
                        <div>
                            Personal Messages
                        </div>
                    </div>
                    <div className="personalchat-list-sidebar">
                        {
                            usersChatRooms.map(item => (

                                <Channel key={item.id} className={`${window.location.pathname.split('/')[2] === item.roomId ? 'channel-sidebar-active' : 'channel-sidebar'}`} onClick={() => goToDM(item.roomId)}>

                                    <p>
                                        <span className={item.active_status === "active" ? "greendot-sidebar" : "reddot-sidebar"} >
                                        </span>
                                        <span>
                                            {item.otherUserName}
                                        </span>
                                    </p>

                                    {item.unreadmessages > 0 && <span className='unread-messages'>{item.unreadmessages > 99 ? '99+' : item.unreadmessages}</span>}
                                </Channel>
                            ))
                        }
                    </div>
                </div>
            </div>
            <Modals open={openModal} handleClose={() => setOpenModal(false)} taskInputComponent={<TaskInput />} />
        </div>

        </>
    )
}

export default Sidebar





const Channel = styled.div`
    height: 28px;
    display: flex;
    align-items: center;
    padding-left: 19px;
    cursor: pointer;
    gap:5px;
    :hover {
        background: #350D36;
    }
`
