import { useCallback, useEffect, useContext, useState } from 'react';
import { Context } from "../Context/NoteContext";
import db from "../firebase";

const UseallCompletedTasks = ({ enabled = false }) => {
    const [allUser, setallUser] = useState([]);

    const context = useContext(Context);
    const { taskUpdate, settaskUpdate } = context;

    const getTimestampFromSecondsAndNanoSeconds = ({ seconds, nanoseconds }) => {
        return seconds * 1000 + nanoseconds / 1000000;
    }
    const fetchTasks = useCallback((value = false) => {
        const userId = localStorage.getItem("userId");

        if (!userId) return;

        const queryTasks = () => {
            const oldestTask = allUser?.sort((a, b) => getTimestampFromSecondsAndNanoSeconds(a.created_on_tt) - getTimestampFromSecondsAndNanoSeconds(b.created_on_tt))?.[0];
            let query = null;
            if (oldestTask?.created_on_tt && !value) {
                query = db
                    .collection("Task")
                    .orderBy('created_on_tt', 'desc')
                    .startAfter(oldestTask.created_on_tt)
                    .where('status', '==', 'Complete')
                    .limit(5);
            } else {
                query = db
                    .collection("Task")
                    .orderBy('created_on_tt', 'desc')
                    .where('status', '==', 'Complete')
                    .limit(5);
            }

            query
                .get()
                .then(snapshot => {
                    const users = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
                    if (value) setallUser([...users]);
                    else setallUser([...allUser, ...users]);
                })
                .catch(error => console.error(error));
        };

        queryTasks();
        console.log(allUser)

    }, [allUser]);

    useEffect(() => {
        if (enabled) {
            fetchTasks();
        }
    }, [enabled]);

    useEffect(() => {
        if (taskUpdate) {
            fetchTasks(true);
            settaskUpdate(false)
        }
    }, [taskUpdate]);

    return { tasks: allUser, fetchNext: fetchTasks };
};

export default UseallCompletedTasks;