import React, { useEffect } from "react";
import "./Styles/PinnedMessages.css";
import { RiUnpinFill } from "react-icons/ri";
import db from "../firebase";
import firebase from "firebase/compat/app";


const PinnedMessages = ({ Pinnedmessages,channelId,PersonalChat }) => {
  function replaceMentions(input) {
    const mentionRegex = /\@\[([^\]]+)\]\(([^\)]+)\)/g;
    return input.replace(mentionRegex, '<span style="color: blue;">@$1</span>');
  }

  const convertTextToHTMLoneLine = (text) => {
    const replacementions = replaceMentions(text);

    const urlRegex = /(https?:\/\/[^\s]+)/g;
    let htmlContent = replacementions.replace(
      urlRegex,
      (url) => `<a href="${url}" target="_blank">${url}</a>`
    );

    const brIndex = htmlContent.indexOf("<br>");
    if (brIndex !== -1) {
      htmlContent = htmlContent.substring(0, brIndex);
    }

    return {
      __html: htmlContent,
    };
  };




  const UnpinMessage = (messageId,text) => {
    
    if(PersonalChat===true)
    {
     const personalMessagesCollection = db.collection("personalMessages");
     personalMessagesCollection.doc(channelId).update({
        PinnedMessages: firebase.firestore.FieldValue.arrayRemove({ MessageId: messageId,text:text })
    });
    }
    else
    {
     const personalMessagesCollection = db.collection("rooms");
     personalMessagesCollection.doc(channelId).update({
        PinnedMessages: firebase.firestore.FieldValue.arrayRemove({ MessageId: messageId,text:text })
    });
    }
  
}

  return (
    <>
      {Pinnedmessages.length > 0 && (
        <div className="PinnedMessages-Container">
          {Pinnedmessages.map((message) => (
            <div className="PinnedMessages-Messages">
            <div
              key={message.MessageId}
              dangerouslySetInnerHTML={convertTextToHTMLoneLine(message.text)}
              ></div>
              
            <span onClick={()=>{UnpinMessage(message.MessageId,message.text
              
              )}} className="PinnedMessages-Unpin"><RiUnpinFill/></span>
              </div>
          ))}
        </div>
      )}
    </>
  );
};

export default PinnedMessages;
