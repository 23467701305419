import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { BsInfoCircle, BsTicket } from "react-icons/bs";
import ChatInput from "./ChatInput";
import ChatMessage from "./ChatMessage";
import { useNavigate, useParams } from "react-router-dom";
import db from "../firebase";
import firebase from "firebase/compat/app";
import { BsPlus } from "react-icons/bs";
import "./Styles/Chat.css";
import { MdMoreHoriz } from "react-icons/md";
import { IoMdMore } from "react-icons/io";
import ChatSideBar from "./ChatSideBar";
import { Context } from "../Context/NoteContext";
import TaskInput from "./TaskInput";
import Modals from "./Common/Models";
import Spinner from "./Spinner";
import { sendPushNotification } from "../utils/firebase";
import { useUsersDetails } from "../Context/UsersDetailsContext";
import { FaCircleInfo } from "react-icons/fa6";
import { FaEdit, FaSave } from "react-icons/fa";
import { GrCheckboxSelected } from "react-icons/gr";
import PinnedMessages from "./PinnedMessages";
import PreviewFile from "./PreviewFile";
function Chat({}) {
  const [showspinner, setshowspinner] = useState(true);
  const messageContainerRef = useRef(null);
  const context = useContext(Context);
  const {
    ShowPreviewFile , setShowPreviewFile,PreviewFileimage,setPreviewFileimage,
    alert,
    activeunreadmessagesCount,
    setactiveunreadmessagesCount,
  } = context;
  const { getUser,user,
    UserList,
    userlists } = useUsersDetails();

  const navigate = useNavigate();

  let { channelId } = useParams();
  const [channel, setChannel] = useState();
  const [channeldescription, setchanneldescription] = useState("");
  const [channeldescriptionedit, setchanneldescriptionedit] = useState("");

  const [messages, setMessages] = useState([]);
  const [Pinnedmessages, setPinnedmessages] = useState([]);
  const [taskTitle, settaskTitle] = useState("");

  const [LatestTimeStamp, setLatestTimeStamp] = useState();
  const [userjoinTime, setuserjoinTime] = useState();
  
  const [reportingPersonId, setreportingPersonId] = useState("");

  const [newusers, setnewusers] = useState([]);
  const [existingusers, setexistingusers] = useState([]);
  const [Restricted, setRestricted] = useState(true);

  const channelUnsubscribe = useRef(null);
  const messagesUnsubscribe = useRef(null);

  const deletechannel = async () => {
    if (user.role === "admin") {
      navigate("/");
      const roomRef = db.collection("rooms").doc(channelId);
      roomRef
        .delete()
        .then(() => {
          console.log(`Room ${channelId} deleted successfully`);
          alert("danger", "Channel deleted", 1200);
        })
        .catch((error) => {
          console.error("Error deleting room:", error);
        });
    } else {
      alert("warning", "Access Denied", 1200);
    }
  };

  const [translateX, setTranslateX] = useState("hidden");

  const handleTranslateClick = () => {
    setTranslateX("inherit"); // Adjust the translation amount as needed
  };

  const getMessages = (userJoinTime, messageLimit) => {
    if (Object.keys(UserList).length > 1) {
      return db
        .collection("rooms")
        .doc(channelId)
        .collection("messages")
        .where("timestamp", ">=", userJoinTime)
        .orderBy("timestamp", "desc")
        .limit(10)
        .onSnapshot(snapshot => {
          let newMessages = [];

          snapshot.docs.forEach(doc => {
            const messageData = {
              id: doc.id,
              user: UserList[doc.data().userID]?.username,
              userImage: UserList[doc.data().userID]?.image,
              status: UserList[doc.data().userID]?.status,
              ...doc.data(),
            };

            newMessages.push(messageData);
          });

          newMessages.length>0&&setLatestTimeStamp(newMessages[0].timestamp)
          setMessages(newMessages);

          setshowspinner(false);
        });
    }
  };

  useEffect(() => {
    const container = messageContainerRef.current;
    if (container) {
      container.scrollTop = 0;
    }
  }, [LatestTimeStamp])
  

  const sendMessage = (text, mentions) => {
    setactiveunreadmessagesCount(0);
    const formattedText = text.replace(/\n/g, "<br>");

    if (channelId) {
      let payload = {
        text: formattedText,
        timestamp: firebase.firestore.Timestamp.now(),
        userID: user.uid,
        reactions: [],
        unreaded_by: existingusers
          .filter((member) => member.uid !== user.uid)
          .map((member) => member.uid),
        mentioned_users: mentions,
      };
      db.collection("rooms")
        .doc(channelId)
        .collection("messages")
        .add(payload)
        .then((docRef) => {
          SendToMentionedUsers(docRef.id, mentions);
          sendPushNotification(
            existingusers
              .filter((member) => member.uid !== user.uid)
              .map((member) => getUser(member.uid)?.notification_token)
              .filter(Boolean),
            `[${channel.name}] - ${user.name}`,
            text
          );
        })
        .catch((error) => {
          console.error("Error adding message:", error);
        });
    }
  };

  // TO SEND CHAT TO MENTIONED USERS
  const SendToMentionedUsers = (messageid, userstosend) => {
    Object.keys(userstosend).forEach((userId) => {
      const userDocRef = db.collection("MentionedMessages").doc(userId);
      const payload = {
        messageID: messageid,
        channelID: channelId,
        timestamp: firebase.firestore.Timestamp.now(),
      };

      db.collection("MentionedMessages")
        .doc(userId)
        .collection("messages")
        .add(payload);
    });
  };
  // TO SEND CHAT TO MENTIONED USERS

  const getChannel = () => {
    if (channelId) {
      if (channelUnsubscribe.current) {
        channelUnsubscribe.current();
      }

      channelUnsubscribe.current = db
        .collection("rooms")
        .doc(channelId)
        .onSnapshot((snapshot) => {
          setChannel(snapshot.data());

          if (snapshot.data().PinnedMessages !== undefined) {
            setPinnedmessages(snapshot.data().PinnedMessages)
          }

          if (snapshot.data().description === undefined) {
            setchanneldescription("No Description");
            setchanneldescriptionedit("");
          } else {

            const urlRegex = /(https?:\/\/[^\s]+)/g;

            // const formattedText = snapshot.data().description.replace(urlRegex, (url) => `<a href="${url}" target="_blank">${url}</a>`);

            // const Description = formattedText.replace(/\n/g, "<br>");

            const Description = snapshot.data().description.replace(urlRegex, (url) => `<a href="${url}" target="_blank">${url}</a>`);

            setchanneldescription(Description);
            setchanneldescriptionedit(snapshot.data().description);
          }

          if (snapshot.data() !== undefined) {
            fetchUsernamesAndIds(snapshot.data().members);

            const targetUser = snapshot
              .data()
              .members.find((userr) => userr.userid === (user && user.uid));

            if (targetUser) {
              setRestricted(targetUser.isRestricted);

              if (messagesUnsubscribe.current) {
                messagesUnsubscribe.current();
              }

              setuserjoinTime(targetUser.joinTime)
              messagesUnsubscribe.current = getMessages(targetUser.joinTime);
            }
          }
        });
    }
  };

  useEffect(() => {
    setshowspinner(true);
    getChannel();

    return () => {
      if (channelUnsubscribe.current) {
        channelUnsubscribe.current();
      }
      if (messagesUnsubscribe.current) {
        messagesUnsubscribe.current();
      }
    };
  }, [channelId, user, UserList]);

  const fetchUsernamesAndIds = async (members) => {
    const existingMembers = members || [];
    
    

    const newusersData = [];
    const existingusersData = [];
    
    userlists.forEach((doc) => {
      const userId = doc.userId;
      const username = doc.username;
      const image = doc.image;
      const role = doc.role;

      const targetUser = existingMembers.find((user) => user.userid === userId);

      if (!targetUser) {
        const userData = {
          uid: userId,
          username: username,
          image: image,
          role: role,
        };
        newusersData.push(userData);
      } else {
        const userData = {
          uid: userId,
          username: username,
          image: image,
          role: role,
          isRestricted: targetUser.isRestricted,
          joinTime: targetUser.joinTime,
        };
        existingusersData.push(userData);
      }
    });

    setnewusers(newusersData);
    setexistingusers(existingusersData);
  };

  const adduser = (newMemberId, role) => {
    if (user.role === "admin" || user.role === "manager") {
      const roomRef = db.collection("rooms").doc(channelId);
      const restricted = role === "admin" || role === "manager";

      roomRef
        .update({
          members: firebase.firestore.FieldValue.arrayUnion({
            userid: newMemberId,
            isRestricted: !restricted,
            joinTime: firebase.firestore.Timestamp.now(),
          }),
        })
        .then(() => {})
        .catch((error) => {
          console.error("Error adding new member to room:", error);
        });
    }
  };

  // TO MARK COMMENTS AS READ
  useEffect(() => {
    const markAllMessagesAsRead = async () => {
      try {
        const messagesRef = db
          .collection("rooms")
          .doc(channelId)
          .collection("messages");

        const messagesSnapshot = await messagesRef.get();

        messagesSnapshot.forEach((messageDoc) => {
          const messageId = messageDoc.id;
          const messageRef = messagesRef.doc(messageId);

          messageRef.update({
            unreaded_by: firebase.firestore.FieldValue.arrayRemove(user.uid),
          });
        });
      } catch (error) {
        console.error("Error marking messages as read:", error);
      }
    };

    markAllMessagesAsRead();
  }, [messages, channelId]);

  // TO MARK COMMENTS AS READ


  const updatedescription = () => {

    const formatedtext = channeldescriptionedit.replace(/\n/g, "<br>");

    if (channelId) {
      db.collection("rooms")
        .doc(channelId)
        .update({ description: formatedtext })
        .then(() => {
          console.log("Channel name updated successfully!");
        })
        .catch((error) => {
          console.error("Error updating channel name: ", error);
        });
    }
  };

  const [showdescription, setshowdescription] = useState(false);
  const [descriptiondisabled, setdescriptiondisabled] = useState(true);

  const convertTextToHTML = (text) => {

    return {
      __html: text,
    };
  };

  const convertTextToHTMLoneLine = (text) => {
    const brIndex = text.indexOf('<br>');
    if (brIndex !== -1) {
        text = text.substring(0, brIndex);
    }
    text = text.substring(0, 60);

    return {
        __html: text,
    };
};
  


const fetchOldMessages = () => {
    const oldestMessage = messages.reduce((oldest, current) =>
      oldest.timestamp < current.timestamp ? oldest : current
    );

    db.collection("rooms")
      .doc(channelId)
      .collection("messages")
      .where("timestamp", ">=", userjoinTime)
      .orderBy('timestamp', 'desc')
      .startAfter(oldestMessage.timestamp)
      .limit(10)
      .get()
      .then(res =>
        {
          let newMessages = [];

          res.docs.forEach(doc => {
            const messageData = {
              id: doc.id,
              user: UserList[doc.data().userID]?.username,
              userImage: UserList[doc.data().userID]?.image,
              status: UserList[doc.data().userID]?.status,
              ...doc.data(),
            };

            newMessages.push(messageData);
          });

          setMessages(prevMessages => [...prevMessages, ...newMessages]);

        }
        // setMessages(prevMessages => [
        //   ...prevMessages,
        //   ...res.docs.map(doc => ({ ...doc.data(), id: doc.id })),
        // ])
      )
      .catch(err => console.log(err));
  };

  
  let isFetchDisabled = false;

  const handleScroll = () => {
    const element = messageContainerRef.current;
    const scrollbarPos = element.clientHeight - element.scrollTop + 5;
  
    if (scrollbarPos > element.scrollHeight && !isFetchDisabled) {
      fetchOldMessages();
  
      // Disable fetch for 1 second
      isFetchDisabled = true;
      setTimeout(() => {
        isFetchDisabled = false;
      }, 1000);
    }
  };
  


  return (
    <>
      {showspinner ? (
        <Spinner />
      ) : (
        <div class="container-chat">
          <div class="header">
            <div class="channel">
              <div>
                <div class="channel-name">
                  You are in Channel - {channel && channel.name}
                </div>

                <div class="channel-info">
                  <span className="channel-desc" dangerouslySetInnerHTML={convertTextToHTMLoneLine(channeldescription)}>
                   
                  </span>{" "}
                  <span
                    className=" channel-desc-info"
                    onClick={() => setshowdescription(true)}
                  >
                    <FaCircleInfo />
                  </span>
                  {showdescription && (
                    <div
                      onClick={() => {
                        setshowdescription(false);
                        setdescriptiondisabled(true);
                      }}
                      className="showdesc-background"
                    ></div>
                  )}
                  {showdescription && (
                    <div className="channel-description">
                      {descriptiondisabled ? (
                        <div className="ViewChannelDesc" dangerouslySetInnerHTML={convertTextToHTML(channeldescription)}>
          
                        </div>
                      ) : (
                        <textarea
                          type="text"
                          disabled={descriptiondisabled}
                          value={channeldescriptionedit.replace(/<br>/g, '\n')}
                          onChange={(e) =>
                            setchanneldescriptionedit(e.target.value)
                          }
                        />
                      )}

                      {(user.role === "admin" || user.role === "manager") && (
                        <div className="desc-icons">
                          <GrCheckboxSelected
                            onClick={() => {
                              updatedescription();
                              setshowdescription(false);
                              setdescriptiondisabled(true);
                            }}
                          />
                          <FaEdit
                            onClick={() => {
                              setdescriptiondisabled(false);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div
                className="more-chat"
                onClick={() => {
                  handleTranslateClick();
                }}
              >
                <IoMdMore className="more-chat-icon" />
              </div>
            </div>
          </div>

          <div class="message-container" onScroll={handleScroll} ref={messageContainerRef}>
            {messages.length > 0 && (
              <>
                {messages.map((data, index) =>
                  data.filename === undefined ? (
                    <div
                      onClick={() => {
                        settaskTitle(data.text);
                        setreportingPersonId(data.userID);
                      }}
                    >
                      {activeunreadmessagesCount === index + 1 && (
                        <div className="unread-messages-seperator">
                          Unread Messages
                        </div>
                      )}
                      <ChatMessage
                        fileP={false}
                        key={data.id}
                        uid={data.userID}
                        text={data.text}
                        name={data.user}
                        image={data.userImage}
                        timestamp={data.timestamp}
                        PersonalChat={false}
                        messageId={data.id}
                        status={data?.status}
                        channelId={channelId}
                        reactions={data.reactions}
                        taskTitle={taskTitle}
                        reportingPersonId={reportingPersonId}
                      />
                    </div>
                  ) : (
                    <ChatMessage
                      fileP={true}
                      key={data.id}
                      uid={data.userID}
                      filename={data.filename}
                      download={data.downloadLink}
                      name={data.user}
                      image={data.userImage}
                      timestamp={data.timestamp}
                      PersonalChat={false}
                      messageId={data.id}
                      channelId={channelId}
                      reactions={data.reactions}
                      reportingPersonId={reportingPersonId}
                    />
                  )
                )}
              </>
            )}

                    <PinnedMessages Pinnedmessages={Pinnedmessages} PersonalChat={false} channelId={channelId} />

                    {ShowPreviewFile && <PreviewFile PersonalChat={false} channelId={channelId} />}
          </div>

          <ChatInput
            channelId={channelId}
            sendMessage={sendMessage}
            Restricted={Restricted}
            existingusers={existingusers}
            PersonalChat={false}
            SendToMentionedUsers={SendToMentionedUsers}
          />

          <ChatSideBar
            channelId={channelId}
            channelname={channel.name}
            translateX={translateX}
            existingusers={existingusers}
            setexistingusers={setexistingusers}
            setnewusers={setnewusers}
            adduser={adduser}
            newusers={newusers}
            setTranslateX={setTranslateX}
            deletechannel={deletechannel}
          />
        </div>
      )}
    </>
  );
}

export default Chat;
