import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import firebase from 'firebase/compat/app';
import db from '../firebase';
import './Styles/ChatMessage.css';
import { Context } from '../Context/NoteContext';
import { useNavigate } from 'react-router-dom';
import Picker from 'emoji-picker-react';
import { MdEmojiEmotions } from 'react-icons/md';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { IoMdDownload } from 'react-icons/io';
import { FaRegFilePdf } from 'react-icons/fa';
import { FaFileImage } from 'react-icons/fa';
import { FaFile } from 'react-icons/fa';
import { BsFiletypeSvg } from 'react-icons/bs';
import { MdAddTask } from "react-icons/md";
import Emoji from './Emoji';
import TaskInput from './TaskInput';
import Modals from './Common/Models';
import statusOptions from '../data/StatusOption';
import { PiPushPinFill } from "react-icons/pi";

function ChatMessage({
  text,
  name,
  status,
  image,
  channelId,
  timestamp,
  uid,
  messageId,
  PersonalChat,
  reactions,
  fileP,
  filename,
  download,
  taskTitle,
  reportingPersonId,
}) {
  const context = useContext(Context);
  const { user, usersChatRooms, setusersChatRooms, UserList, openModal, setOpenModal } = context;
  const navigate = useNavigate();

  const [reactionlists, setreactionlists] = useState([]);
  const [activemessageID, setactivemessageID] = useState();
  const [emojilibrary, setemojilibrary] = useState(false);

  const [ReactionmessageId, setReactionmessageId] = useState();
  const [reactionCount, setreactionCount] = useState([]);

  // TO CHECK IF TEXT HAS URL OR NOT
  const convertTextToHTML = (text) => {

    const replacementions = replaceMentions(text);
    
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const htmlContent = replacementions.replace(urlRegex, (url) => `<a href="${url}" target="_blank">${url}</a>`);

    return {
      __html: htmlContent,
    };
  };
  // TO CHECK IF TEXT HAS URL OR NOT


  // TO CHECK IF TEXT HAS MENTIONS OR NOT
 function replaceMentions(input) {
  const mentionRegex = /\@\[([^\]]+)\]\(([^\)]+)\)/g;
  return input.replace(mentionRegex, '<span style="color: blue;">@$1</span>');
}
  // TO CHECK IF TEXT HAS MENTIONS OR NOT


  const updateReactionCount = (reactionsData) => {
    if (reactionsData && channelId && messageId) {
      const reactionCount = {};
      reactionsData.forEach((reaction) => {
        const emoji = reaction.emoji;
        reactionCount[emoji] = (reactionCount[emoji] || 0) + 1;
      });
      const reactionCountArray = Object.keys(reactionCount).map((emoji) => ({
        emoji: emoji,
        count: reactionCount[emoji],
      }));
      setreactionCount(reactionCountArray);
    }
  };

  useEffect(() => {
    updateReactionCount(reactions);
    const reactiondata = [];
    for (let i = 0; i < reactions.length; i++) {
      const data = {
        emoji: reactions[i].emoji,
        userId: reactions[i].userId,
        userImage: UserList[reactions[i].userId].image,
        username: UserList[reactions[i].userId].username,
      };
      reactiondata.push(data);
    }
    setreactionlists(reactiondata);
  }, []);

  const checkOrCreateChatRoom = async (user1ID, user2ID, name, image) => {
    if ((user1ID !== user2ID) && (user.role === 'admin' || user.role === 'manager')) {
      const users = [user1ID, user2ID];
      users.sort();
      try {
        const roomQuery = await db.collection('personalMessages').where('users', '==', users).get();
        if (!roomQuery.empty) {
          // Chat room already exists
          const room = roomQuery.docs[0];
          navigate(`/personalroom/${room.id}`);
        } else {
          // Create a new chat room
          const newRoomRef = await db.collection('personalMessages').add({
            users,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          });
          navigate(`/personalroom/${newRoomRef.id}`);
        }
      } catch (error) {
        console.error('Error checking or creating chat room:', error);
      }
    }
  };

  const mouseenter = () => {
    setactivemessageID(messageId);
  };

  const mouseexit = () => {
    setactivemessageID();
  };


  const getStatusColor = (status) => {
    const option = statusOptions.find((option) => option.value === status);
    return option ? option.color : '#000000';
  };

  // FOR Reaction BAR
  const [isopen, setisopen] = useState(false);

  const handleReaction = (emoji) => {
    if (emoji !== '➕') {
      updateReaction(emoji);
      setisopen(false);
      setemojilibrary(false);
    } else {
      setemojilibrary(true);
    }
  };

  const clicked = (e) => {
    updateReaction(e.emoji);
    setisopen(false);
    setemojilibrary(false);
  };

  // to update reaction
  const updateReaction = (emoji) => {
    if (channelId && messageId) {
      const userReaction = {
        userId: user.uid,
        emoji: emoji,
      };
      db.collection(`${PersonalChat === true ? 'personalMessages' : 'rooms'}`)
        .doc(channelId)
        .collection('messages')
        .doc(messageId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const reactions = doc.data().reactions || [];
            // Check if the user has already reacted
            const existingReactionIndex = reactions.findIndex((reaction) => reaction.userId === user.uid);
            if (existingReactionIndex !== -1) {
              reactions[existingReactionIndex].emoji = emoji;
            } else {
              reactions.push(userReaction);
            }
            db.collection(`${PersonalChat === true ? 'personalMessages' : 'rooms'}`)
              .doc(channelId)
              .collection('messages')
              .doc(messageId)
              .update({
                reactions: reactions,
              })
              .then(() => {
                updateReactionCount(reactions);
                const reactiondata = [];
                for (let i = 0; i < reactions.length; i++) {
                  const data = {
                    emoji: reactions[i].emoji,
                    userId: reactions[i].userId,
                    userImage: UserList[reactions[i].userId].image,
                    username: UserList[reactions[i].userId].username,
                  };
                  reactiondata.push(data);
                }
                setreactionlists(reactiondata);
              })
              .catch((error) => {
                console.error('Error updating reaction:', error);
              });
          } else {
            console.log(`Message with ID ${messageId} not found`);
          }
        })
        .catch((error) => {
          console.error('Error fetching message data:', error);
        });
    }
  };

  //  to remove reaction
  const removeReaction = (reacteduserId) => {
    if (reacteduserId === user.uid) {
      if (channelId && messageId) {
        db.collection(`${PersonalChat === true ? 'personalMessages' : 'rooms'}`)
          .doc(channelId)
          .collection('messages')
          .doc(messageId)
          .get()
          .then((doc) => {
            if (doc.exists) {
              const reactions = doc.data().reactions || [];
              // Find the index of the user's reaction
              const userReactionIndex = reactions.findIndex((reaction) => reaction.userId === user.uid);
              if (userReactionIndex !== -1) {
                // Remove the user's reaction from the array
                reactions.splice(userReactionIndex, 1);
                // Update the "reactions" array in Firestore
                db.collection(`${PersonalChat === true ? 'personalMessages' : 'rooms'}`)
                  .doc(channelId)
                  .collection('messages')
                  .doc(messageId)
                  .update({
                    reactions: reactions,
                  })
                  .then(() => {
                    updateReactionCount(reactions);
                    const reactiondata = [];
                    for (let i = 0; i < reactions.length; i++) {
                      const data = {
                        emoji: reactions[i].emoji,
                        userId: reactions[i].userId,
                        userImage: UserList[reactions[i].userId].image,
                        username: UserList[reactions[i].userId].username,
                      };
                      reactiondata.push(data);
                    }
                    setreactionlists(reactiondata);
                    updateReactionCount(messageId);
                  })
                  .catch((error) => {
                    console.error('Error updating reactions:', error);
                  });
              } else {
                // console.log(`User ${user.uid} has not reacted to message ${messageId}`);
              }
            } else {
              // console.log(`Message with ID ${messageId} not found`);
            }
          })
          .catch((error) => {
            console.error('Error fetching message data:', error);
          });
      }
    }
  };


  const Pinmessage = ()=>{
   const data = {
      MessageId : messageId,
      text:text
    }
    if(PersonalChat===true)
    {
      const roomRef = db.collection("personalMessages").doc(channelId);
      roomRef.update({ 
        PinnedMessages: firebase.firestore.FieldValue.arrayUnion(data)
    });
    }
    else
    {
      const roomRef = db.collection("rooms").doc(channelId);
      roomRef.update({ 
        PinnedMessages: firebase.firestore.FieldValue.arrayUnion(data)
    });
    }
   
  }

  return (
    <div
      onMouseEnter={(e) => {
        setReactionmessageId(messageId);
      }}
      onMouseLeave={(e) => {
        setReactionmessageId();
        setisopen(false);
        setemojilibrary(false);
      }}
      className={`${user.uid === uid ? 'container-ownchatmessage' : 'container-chatmessage'} `}
    >
      <div className={`${user.uid === uid ? 'owntext-container' : 'text-container'} ${reactionCount.length > 0 ? 'reactionCountpresent' : ''} `}>
        {uid !== user.uid && (
          <div className="user-avatar" onMouseEnter={mouseenter} onMouseLeave={mouseexit}>
            <img src={image} />
            <div
              onClick={() => {
                checkOrCreateChatRoom(uid, user.uid, name, image);
              }}
              className={`${uid !== user.uid ? activemessageID === messageId ? (PersonalChat !== true ? 'gotochat' : 'gotochathide') : 'gotochathide' : 'gotochathide'} `}
            >
              Go to Chat
            </div>
          </div>
        )}
        <div className={`${user.uid === uid ? 'message-contentown' : 'message-content'} `}>
          <span className={`${user.uid === uid ? 'nameown' : 'name'} `}>
            {name}
            <span>
              {new Date(timestamp.toDate()).toLocaleString('en-IN', {
                weekday: 'short',
                day: 'numeric',
                month: 'short',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              })}
            </span>
            <span onClick={()=>{Pinmessage()}} className='PinMessage'><PiPushPinFill/> <p className='Pinmessage-Pin'>Pin</p> </span>
            {status ? (
              <div className="name">
                {' '}
                <span style={{ marginLeft: '1px', color: getStatusColor(status) }}> {status}</span>
              </div>
            ) : (
              ''
            )}
          </span>
          {fileP === false ? (
            <>
              <span id="text" className="text" dangerouslySetInnerHTML={convertTextToHTML(text)} />
            </>
          ) : filename.split('.')[1] === 'pdf' ? (
            <span className="file">
              <p className="filename">
                <span className="filetype">
                  <FaRegFilePdf />
                </span>
                {filename.length > 30 ? filename.slice(0, 30) + '....' : filename}
              </p>
              <p onClick={() => (window.location.href = download)} className="download">
                <IoMdDownload />
              </p>
            </span>
          ) : ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'webp', 'ico'].includes(filename.split('.')[1]) ? (
            <span className="fileimage">
              <img src={download} alt="" />
            </span>
          ) : filename.split('.')[1] === 'svg' ? (
            <span className="file">
              <p className="filename">
                <span className="filetype">
                  <BsFiletypeSvg />
                </span>
                {filename.length > 30 ? filename.slice(0, 30) + '....' : filename}
              </p>
              <p onClick={() => (window.location.href = download)} className="download">
                <IoMdDownload />
              </p>
            </span>
          ) : ['mp3', 'wav', 'ogg', 'aac', 'flac', 'm4a', 'wma', 'aiff', 'alac'].includes(filename.split('.')[1]) ? (
            <span className="audio-container">
              <audio controls>
                <source src={download} type="audio/mp3" />
              </audio>
            </span>
          ) : (
            <span className="file">
              <p className="filename">
                <span className="filetype">
                  <FaFile />
                </span>
                {filename.length > 34 ? filename.slice(0, 34) + '....' : filename}
              </p>
              <p onClick={() => (window.location.href = download)} className="download">
                <IoMdDownload />
              </p>
            </span>
          )}
        </div>
        {/* To Click on emojis  */}
        {messageId === ReactionmessageId && !isopen && (
          <>
            <MdEmojiEmotions
              onClick={() => setisopen(true)}
              className={`${user.uid === uid ? 'EmojiSelectOwn' : 'EmojiSelect'}`}
            />
            <MdAddTask onClick={() => setOpenModal(true)} style={{ marginTop: "25px" }} className={`${user.uid === uid ? 'EmojiSelectOwn' : 'EmojiSelect'}`} />
          </>
        )}

        <div className={`${user.uid === uid ? 'reaction-barown' : 'reaction-bar'} `}>
          {messageId === ReactionmessageId &&
            isopen &&
            (emojilibrary ? <><Picker reactionsDefaultOpen={false} onEmojiClick={clicked} /> </> : <><Emoji handleReaction={handleReaction} setemojilibrary={setemojilibrary} /> </>)}
        </div>
        {/* To Click on emojis  */}
        {/* to show the reaction from firebase  */}
        {reactionCount.length > 0 && (
          <div className={`${user.uid === uid ? 'reaction-countown' : 'reaction-count'} `}>
            {reactionCount.map((reaction) => (
              <PopupState variant="popover" popupId="demo-popup-popover" key={reaction.emoji}>
                {(popupState) => (
                  <div className="reaction-details">
                    <div className="reactions" variant="contained" {...bindTrigger(popupState)}>
                      <p className="reactions-emoji"> {reaction.emoji}</p>
                      <p className="reactions-count"> {reaction.count}</p>
                    </div>
                    {reactions.length > 0 && (
                      <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                        PaperProps={{ style: { maxHeight: 300, overflowY: 'auto' }, className: 'custom-scrollbar' }}
                      >
                        <Typography sx={{}}>
                          {reactionlists.map((reactiondetail) => (
                            <div onClick={() => removeReaction(reactiondetail.userId)} className="reaction-container" key={reactiondetail.userId}>
                              <div className="reaction-image-username">
                                <img src={reactiondetail.userImage} alt="" className="user-image-reaction" />
                                <div className="username-reaction-text">
                                  <p className="username-reaction">{reactiondetail.username.length > 22 ? reactiondetail.username.slice(0, 22) + '..' : reactiondetail.username}</p>
                                  {reactiondetail.userId === user.uid && <p className="remove-reaction"> Click to Remove </p>}
                                </div>
                              </div>
                              <p>{reactiondetail.emoji}</p>
                            </div>
                          ))}
                        </Typography>
                      </Popover>
                    )}
                  </div>
                )}
              </PopupState>
            ))}
          </div>
        )}
        {/* to show the reaction from firebase  */}
        <Modals
          open={openModal}
          handleClose={() => setOpenModal(false)}
          taskInputComponent={<TaskInput text={taskTitle} reportingPerson={reportingPersonId} />}
        />
      </div>
    </div >
  );
}

export default ChatMessage;
