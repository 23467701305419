import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { MdSend } from "react-icons/md";
import "./Styles/ChatInput.css";
import { Mention, MentionsInput } from "react-mentions";
import { Context } from "../Context/NoteContext";
import { FiUpload } from "react-icons/fi";
import AWS from "aws-sdk";
import db from "../firebase";
import firebase from "firebase/compat/app";

function ChatInput({ sendMessage, Restricted, PersonalChat,existingusers, channelId }) {
  const context = useContext(Context);
  const { user, userlists ,ShowPreviewFile ,UploadFile,setUploadFile, setShowPreviewFile,PreviewFileimage,setPreviewFileimage} = context;

  const [input, setInput] = useState("");
  const [mentionIds, setmentionIds] = useState({});

  const inputRef = useRef(null);





  
  // STYLING AND FUNCTIONS FOR REACT_MENTION COMPONENT 


  const [suggestionsPosition, setSuggestionsPosition] = useState({ left: 0 });
  const users = existingusers.map((user) => {
    return {
      id: user.uid,
      display: user.username,
      imageUrl: user.image,
    };
  });


  const adjustSuggestionsPosition = () => {
      if (input.length>90) {
        setSuggestionsPosition({ right:'0' });
      } else {
        setSuggestionsPosition({ left: '0' });
      }
    
  };

  // useEffect(() => {
  //   adjustSuggestionsPosition();
  // }, [input]);




  const mentionStyle = {
    position: "relative",
    zIndex: 1,
    color: "blue",
    textShadow:
      "1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white, -1px -1px 1px white",
    textDecoration: "none",
    pointerEvents: "none",
  };



  const mentionsInputStyle = {
    control: {
      backgroundColor: "#fff",
      fontSize: "14px",
      maxWidth: `${window.innerWidth - 260 - 120}px`,
      minWidth: `${window.innerWidth - 260 - 120}px`,
      // maxHeight: '100px',
      marginBottom: '5px',
      display: "flex",
      flex: "1",
      border: "none",
      overflowY: "auto",
      highlighter: {
        padding: 1,
        border: "none",
      },
    },
  
    input: {
      maxWidth: `${window.innerWidth - 260 - 120}px`,
      minWidth: `${window.innerWidth - 260 - 120}px`,
      // maxHeight: '100px',
      marginBottom: '5px',
      display: "flex",
      flex: "1",
      padding: 1,
      border: "none",
      outline: "none",
      // overflowY: "auto",
      // "&::-webkit-scrollbar": {
      //   width: "3px", 
      // },
      // "&::-webkit-scrollbar-thumb": {
      //   backgroundColor: "#888", 
      //   borderRadius: "3px",      
      // },
    },
  
    suggestions: {
      list: {
        backgroundColor: "white",
        border: "1px solid rgba(0,0,0,0.15)",
        fontSize: 13,
        width: "340px",
        position: "absolute",
        bottom: "30px",
        zIndex: 1,
        ...suggestionsPosition
      },
      item: {
        padding: "6px 15px",
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid rgba(0,0,0,0.15)",
        "&focused": {
          backgroundColor: "#cee4e5",
        },
      },
    },
  };
  
  

  // STYLING AND FUNCTIONS FOR REACT_MENTION COMPONENT 







  const [uploadedUrl, setUploadedUrl] = useState(null);

  
  
  const CheckandUpload=(file) =>{
    const fileName = file.name;
        const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp'];

        const isImage = imageExtensions.some(ext => fileName.toLowerCase().endsWith(ext));

        if (isImage) {
            setUploadFile(file)
            const reader = new FileReader();
            reader.onload = function (e) {
                setPreviewFileimage(e.target.result);
            };
            reader.readAsDataURL(file);
            setShowPreviewFile(true);
        } else {
            handleUpload(file);
        }
  }


  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
        CheckandUpload(file)
    }
};





  const handleUpload = async (selectedFile) => {
    const spacesEndpoint = new AWS.Endpoint(
      "https://blr1.digitaloceanspaces.com"
    );
    const s3 = new AWS.S3({
      endpoint: spacesEndpoint,
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    });

    const params = {
      Bucket: "chat-n-task",
      Key:
        PersonalChat === false
          ? `${channelId}/${selectedFile.name}`
          : `${user.uid}/${selectedFile.name}`,
      name: selectedFile.name,
      Body: selectedFile,
      ACL: "public-read",
    };

    console.log(selectedFile);

    try {
      const data = await s3.upload(params).promise();
      console.log("File uploaded successfully. URL:", data);
      setUploadedUrl(data.Location);
      sendFiledata(selectedFile.name, data.Location);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };





  const send = (e) => {
    if( PersonalChat===true)
    {
      e.preventDefault();
      if (!input) return;
      sendMessage(input);
      setInput("");
    }

    else{ 
      e.preventDefault();
      if (!input) return;
      sendMessage(input,mentionIds);
      setInput("");
    }

  };








  const sendFiledata = (name, downLink) => {
    if (channelId) {
      let payload = {
        timestamp: firebase.firestore.Timestamp.now(),
        user: user.name,
        userImage: user.photo,
        userID: user.uid,
        reactions: [],
        filename: name,
        downloadLink: downLink,
      };
      db.collection("rooms").doc(channelId).collection("messages").add(payload);
    }
  };



 
  const changetextInput = (e)=>{
  
    setInput(e.target.value);
   

    const mentionIdlist = {};
    const mentionRegex = /\@\[([^\]]+)\]\(([^)]+)\)/g;
  let match;
  while ((match = mentionRegex.exec(e.target.value)) !== null) {
    const id = match[2];
    mentionIdlist[id] = true;
  }

setmentionIds(mentionIdlist)

  }
 



  const handleKeyDown = (e) => {
    const cursorPosition = inputRef.current.selectionStart;

    if (e.key === 'Enter' && e.shiftKey) {
      const updatedInput = `${input.slice(0, cursorPosition)}\n${input.slice(cursorPosition)}`;
      setInput(updatedInput);

    } else if (e.key === 'Enter') {
      e.preventDefault(); 
      send(e)
    }
  };


  
  const handlePaste = async (event) => {
    const files = Array.from(event.clipboardData.files);
    if (files.length) {
      CheckandUpload(files[0]);
    }

    
  };


  return (
    <div
      className={`${
        user.role === "admin" ||
        user.role === "manager" ||
        PersonalChat === true ||
        Restricted !== true
          ? "container-chatinput"
          : ""
      }`}
    >
      {user.role === "admin" ||
      user.role === "manager" ||
      PersonalChat === true ||
      Restricted !== true ? (



        <div className="input-container-absolute">
        <div className="input-container">
          <form id="input-form">
            {/* INPUT TEXT  */}
            <MentionsInput
            id="input-text"
            placeholder="Type text here..."
            inputRef={inputRef}
              style={mentionsInputStyle}
              value={input}
              onPaste={handlePaste}
              onKeyDown={handleKeyDown}
              onChange={(e) => {
                changetextInput(e)
                
              }}
              markup={"@[__display__]"} // Adjust the markup to include the "@" symbol
            >
              <Mention
              appendSpaceOnAdd={true}
                style={mentionStyle}
                trigger="@"
                displayTransform={(id, display) => `@${display}`}
                data={users}
                renderSuggestion={(suggestion, search, highlightedDisplay) => (
                  <div>
                    <img
                      src={suggestion.imageUrl}
                      alt={suggestion.display}
                      style={{
                        width: 20,
                        height: 20,
                        borderRadius: "50%",
                        marginRight: 5,
                      }}
                    />
                    {highlightedDisplay}
                  </div>
                )}
              />
            </MentionsInput>
            {/* INPUT TEXT  */}
            

            <label className="upload-button">
              <input
                type="file"
                className="file-input"
                onChange={handleFileChange}
                style={{ display: "none" }} // Hide the default file input
              />
              <span className="upload-icon">
                <FiUpload />
              </span>
            </label>

            <button className="send-button" type="submit" onClick={send}>
              <span className="send-icon">&#10148;</span>
            </button>
          </form>
        </div>
        </div>
      ) : (
        <div className="restricted-message">
          Restricted: Messages on this channel are limited.
        </div>
      )}
    </div>
  );
}

export default ChatInput;
