import React, { useEffect, useMemo, useState, useContext } from "react";
import { Box, Paper, TextField, Button, ButtonGroup } from "@mui/material";
import _ from "lodash";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import db from "../firebase";
import EditForm from "./EditForm";
import "./Styles/Chat.css";
import { MdModeEditOutline } from "react-icons/md";
import Modals from './Common/Models';

const Group = () => {
  const [bookmarks, setBookmarks] = useState([]);
  const [editBookmark, setEditBookmark] = useState(null);

  const [editPopup, seteditPopup] = useState(false)
  const groupedBookmarks = useMemo(
    () => bookmarks && _.groupBy(bookmarks, "section"),
    [bookmarks]
  );

  const handleAddBookmark = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const section = formData.get("section");
    const label = formData.get("label");
    const url = formData.get("url");

    // Construct the payload
    const payload = {
      created_on: firebase.firestore.Timestamp.now(),
      section,
      label,
      url,
      created_by: localStorage.getItem("userId"),
    };

    try {
      const newBookmarkRef = await db.collection("bookmarks").add(payload);
      setBookmarks((prevBookmarks) => [
        ...prevBookmarks,
        { id: newBookmarkRef.id, ...payload },
      ]);
      e.target.reset();
    } catch (error) {
      console.error("Error adding bookmark:", error);
      alert("Something went wrong");
    }
  };

  const handleEditBookmark = (bookmarkId) => {
    setEditBookmark(bookmarkId);
    seteditPopup(true)
  };

  const handleSaveEdit = async (label, url) => {
    try {
      await db.collection("bookmarks").doc(editBookmark).update({ label, url });
      console.log("edited")
      setEditBookmark(null);
      seteditPopup(false)
    } catch (error) {
      console.error("Error updating bookmark:", error);
      alert("Something went wrong");
    }
  };


  const fetchBookmarks = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const bookmarksSnapshot = await db
        .collection("bookmarks")
        .where("created_by", "==", userId)
        .get();

      const bookmarksData = bookmarksSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBookmarks(bookmarksData);
    } catch (error) {
      console.error("Error fetching bookmarks:", error);
    }
  };

  useEffect(() => {
    fetchBookmarks();
  }, []);

  useEffect(() => {
    fetchBookmarks();
  }, [editBookmark]);
  return (
    <>
      <div className="message-container">
        <Box
          sx={{
            p: 2,
            backgroundColor: "#ececec",
            minHeight: "100dvh",
          }}
        >
          <Paper
            component="form"
            onSubmit={handleAddBookmark}
            sx={{ display: "flex", gap: 1.5, flexWrap: "wrap", p: 2, mb: 2 }}
          >
            <TextField name="section" label="Section" size="small" />
            <TextField name="label" label="Label" size="small" />
            <TextField name="url" label="URL" size="small" />
            <Button variant="contained" type="submit">
              Submit
            </Button>
          </Paper>

          {groupedBookmarks &&
            Object.entries(groupedBookmarks).map(([section, _bookmarks]) => (
              <Paper
                sx={{
                  display: "flex",
                  gap: 1,
                  flexWrap: "wrap",
                  p: 2,
                  mb: 1,
                }}
                key={section}
              >
                <h3 style={{ flexBasis: "100%", margin: "0px" }}>{section}</h3>
                {_bookmarks.map(({ id, url, label }) => (
                  <> <Modals open={editPopup} handleClose={() => seteditPopup(false)} taskInputComponent={<EditForm
                    bookmark={{ id, url, label }}
                    onSave={(label, url) => handleSaveEdit(label, url)}
                  />} />
                    <ButtonGroup key={id}>
                      <Button
                        size="small"
                        target="_blank"
                        href={url}
                        variant="outlined"
                      >
                        {label}
                      </Button>
                      <Button onClick={() => handleEditBookmark(id)}>
                        <MdModeEditOutline />
                      </Button>
                    </ButtonGroup>
                  </>
                ))}
              </Paper>
            ))}


        </Box>
      </div>
    </>
  );
};

export default Group;
