import React, { useContext, useEffect,useMemo, useState } from "react";
import { Context } from "../Context/NoteContext";
import db from "../firebase";
import firebase from "firebase/compat/app";
import "./Styles/Broadcast.css";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import ChatInput from "./ChatInput";
import { TailSpin } from "react-loader-spinner";
import { sendPushNotification } from "../utils/firebase";
import { useUsersDetails } from "../Context/UsersDetailsContext";


const Broadcast = () => {

  const { getUser,userlists, user } = useUsersDetails();
  const [receiverId, setReceiverId] = useState();

  const receiverUser = useMemo(
    () => getUser(receiverId),
    [getUser, receiverId]
  );
  
  const [showbroadcastloader, setshowbroadcastloader] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [expandedDepartments, setExpandedDepartments] = useState([]);
  const [checkedUsers, setCheckedUsers] = useState({});

  useEffect(() => {
    const fetchDepartments = async () => {
      const dept = await db
        .collection("config")
        .doc("department")
        .get()
        .then((doc) => doc.data().department);
      setDepartments(dept);
    };
    fetchDepartments();
  }, [userlists]);

  const toggleUsers = (dept) => {
    if (expandedDepartments.includes(dept)) {
      setExpandedDepartments(expandedDepartments.filter((d) => d !== dept));
    } else {
      setExpandedDepartments([...expandedDepartments, dept]);
    }
  };

  const toggleUserCheckbox = (userId, department) => {
    const updatedCheckedUsers = { ...checkedUsers };
    if (
      updatedCheckedUsers[department] &&
      updatedCheckedUsers[department].includes(userId)
    ) {
      updatedCheckedUsers[department] = updatedCheckedUsers[department].filter(
        (id) => id !== userId
      );
    } else {
      updatedCheckedUsers[department] = updatedCheckedUsers[department]
        ? [...updatedCheckedUsers[department], userId]
        : [userId];
    }

    // Check if the department has no user IDs associated with it
    if (updatedCheckedUsers[department].length === 0) {
      delete updatedCheckedUsers[department];
    }

    setCheckedUsers(updatedCheckedUsers);

    console.log(updatedCheckedUsers);
  };

  const toggleDepartmentCheckbox = (dept) => {
    const updatedCheckedUsers = { ...checkedUsers };
    if (updatedCheckedUsers[dept]) {
      delete updatedCheckedUsers[dept];
    } else {
      updatedCheckedUsers[dept] = userlists
        .filter((user) => user.department === dept)
        .map((user) => user.userId);
    }
    setCheckedUsers(updatedCheckedUsers);
    console.log(updatedCheckedUsers);
  };

  const SendBroadcast = async (text) => {
    setshowbroadcastloader(true);
    try {
      for (const department in checkedUsers) {
        for (const id of checkedUsers[department]) {
          setReceiverId(id);
          await checkOrCreateChatRoom(user.uid, id, text);
        }
      }
    } catch (error) {
      console.error("Error sending broadcast:", error);
    }
    setshowbroadcastloader(false);
  };
  

  const sendMessage = async (text, channelId, userId) => {
    setCheckedUsers({})
    const formattedText = text.replace(/\n/g, "<br>");
    try {
      const messageRef = db
        .collection("personalMessages")
        .doc(channelId)
        .collection("messages");
      await messageRef.add({
        text: formattedText,
        timestamp: firebase.firestore.Timestamp.now(),
        userID: user.uid,
        reactions: [],
        unreaded_by: [userId],
      });
      console.log("Message sent successfully");
      if (receiverUser?.notification_token) {
        sendPushNotification(
          [receiverUser.notification_token],
          `New Message From: ${user.name}`,
          text
        );
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const checkOrCreateChatRoom = async (user1ID, user2ID, text) => {
    return new Promise(async (resolve, reject) => {
      if (
        user1ID !== user2ID &&
        (user.role === "admin" || user.role === "manager")
      ) {
        const users = [user1ID, user2ID];
        users.sort();
        try {
          const roomQuery = await db
            .collection("personalMessages")
            .where("users", "==", users)
            .get();
          if (!roomQuery.empty) {
            const room = roomQuery.docs[0];
            await sendMessage(text, room.id, user2ID);
          } else {
            const newRoomRef = await db.collection("personalMessages").add({
              users,
              createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            });
            await sendMessage(text, newRoomRef.id, user2ID);
          }
          resolve();
        } catch (error) {
          console.error("Error checking or creating chat room:", error);
          reject(error);
        }
      } else {
        resolve(); // Resolve immediately if conditions are not met
      }
    });
  };
  

  return (
    <div className="Broadcast-Container">
      {showbroadcastloader && (
        <div className="spinner-container-broadcast">
          <TailSpin
            visible={true}
            height="80"
            width="80"
            color="#ffffff"
            ariaLabel="tail-spin-loading"
            radius={1}
            wrapperStyle={{}}
            wrapperClass=""
          />
          <div>Broadcasting Message</div>
        </div>
      )}

      <div className="Broadcast-Heading">Broadcast</div>

      <div className="Broadcast-dropdown">
        {departments.map((dept, index) => (
          <div key={index} className="dropdown-item">
            <div className="department-name">
              <span>
                <input
                  type="checkbox"
                  checked={
                    checkedUsers[dept] &&
                    checkedUsers[dept].length ===
                      userlists.filter((user) => user.department === dept)
                        .length
                  }
                  onChange={() => toggleDepartmentCheckbox(dept)}
                />
              </span>{" "}
              {dept}
              <span onClick={() => toggleUsers(dept)} className="arrow">
                {expandedDepartments.includes(dept) ? (
                  <FaCaretUp />
                ) : (
                  <FaCaretDown />
                )}
              </span>
            </div>
            <div
              className={`users ${
                expandedDepartments.includes(dept) ? "show" : ""
              }`}
            >
              {userlists
                .filter((user) => user.department === dept)
                .map((user) => (
                  <div key={user.userId} className="broadcast-username">
                    <span>
                      <input
                        type="checkbox"
                        checked={
                          checkedUsers[dept] &&
                          checkedUsers[dept].includes(user.userId)
                        }
                        onChange={() => toggleUserCheckbox(user.userId, dept)}
                      />
                    </span>{" "}
                    {user.username}
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>

      {/* {Object.keys(checkedUsers).length > 0 && <div className='SendBroadcast' onClick={SendBroadcast}>Send A Broadcast</div>} */}

      {Object.keys(checkedUsers).length > 0 && (
        <ChatInput
          sendMessage={SendBroadcast}
          existingusers={[]}
          PersonalChat={true}
        />
      )}
    </div>
  );
};

export default Broadcast;
