import React, { useState, useEffect, useContext } from 'react';
import useWindowFocus from 'use-window-focus';
import { useUsersDetails } from "../Context/UsersDetailsContext";
import db from '../firebase'

const UserStatus = () => {
  
   const {user} = useUsersDetails();


  const updateUserActiveStatus = (status) => {
    
    const userRef = db.collection('userlists').doc(user.uid && user.uid);

    userRef.update({
      active_status: status,
    })
      .then(() => {
        console.log('User status updated in Firestore:', status);
      })
      .catch((error) => {
        console.error('Error updating user status in Firestore:', error);
      });
  };




  useEffect(() => {

  
  
    
    if(user.uid!=="")
    {
      window.addEventListener('beforeunload', function (e) {
        const userRef = db.collection('userlists').doc(user.uid && user.uid);
    
        userRef.update({
          active_status: 'inactive',
        })
          .catch((error) => {
            console.error('Error updating user status in Firestore:', error);
          });
      
      });




      let timeoutId;
      let activeStatusTimeout = 60 * 30 * 1000;
      
      const handleUserStatus = () => {
        if (document.visibilityState === 'hidden') {
          timeoutId = setTimeout(() => {
            updateUserActiveStatus('inactive')
          }, activeStatusTimeout); 
        } else {
          
          clearTimeout(timeoutId);
          updateUserActiveStatus('active')
        }
      };
      
      document.addEventListener('visibilitychange', handleUserStatus);
      
      return () => {
        document.removeEventListener('visibilitychange', handleUserStatus);
        clearTimeout(timeoutId);
      };
      
    }
  }, [user,document.visibilityState]);

  return (
    <>
    </>
  );
};

export default UserStatus;
